import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";
import {
    formatTextUtf8,
    getDeadServerImgPath,
    getBorderServerImgPath,
    FONTFAMILY,
} from "../../utils/utils";
import FontSource from "../../utils/fonts/Mic32RegularRegular.ttf";
import FontSourceBold from "../../utils/fonts/Mic32BoldRegular.ttf";

// Register font
Font.register({
    family: FONTFAMILY,
    fonts: [
        { src: FontSource }, // font-style: normal, font-weight: normal
        { src: FontSourceBold, fontWeight: "bold" },
    ],
});

const logo_pdf = `/assets/imgs/${process.env.REACT_APP_PDF_LOGO}`;
const pattern_pdf = `/assets/imgs/${process.env.REACT_APP_PDF_PHOTO}`;

let imageUser;

const getWidthPDF = (padding) => {
    if (!padding) {
        padding = 0;
    }

    return 595 - padding * 2;
};
const getHeightPDF = (padding) => {
    if (!padding) {
        padding = 0;
    }

    return 841.7 - padding * 2;
};
const getXNameSpace = (padding) => {
    let ini = 325;
    if (padding && padding > 0) {
        ini -= parseInt(padding) + (parseInt(padding) * 50) / 100;
    }

    return ini;
};
const getYNameSpace = (padding) => {
    let ini = 80;
    if (padding && padding > 0) {
        ini += (parseInt(padding) * 85) / 100 / 100;
    }

    return ini;
};
const Fotografia = (user) => {
    user = user.hasOwnProperty("user") ? user.user : user;
    imageUser =
        user.imageCrop && user.imageCrop !== ""
            ? user.imageCrop
            : getDeadServerImgPath(user.image, user.id);
    console.log("imageUser", imageUser);
    const ImageWidthConst = 2.5;
    const styles = StyleSheet.create({
        pageBackground: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            objectFit: "cover",
            fontFamily: FONTFAMILY,
        },
        pageBackgroundStatic: {
            position: "absolute",
            width: getWidthPDF(user.paddingPhoto) - 80,
            marginLeft: 40,
            marginRight: 40,
            height: getHeightPDF(user.paddingPhoto) / 2,
            left: 0,
            bottom: 30,
            objectFit: "fit",
            fontFamily: FONTFAMILY,
        },
        pageBottomStatic: {
            position: "absolute",
            width: 170,
            height: 140,
            right: 20,
            bottom: -40,
            objectFit: "contain",
            fontFamily: FONTFAMILY,
        },
        page: {
            padding: 0,
            backgroundColor: user.colorBorder,
            fontFamily: FONTFAMILY,
            position: "relative",
        },
        pageBackgroundColor: {
            position: "relative",
            backgroundColor: "white",
            width: getWidthPDF(user.paddingPhoto), //(550 - 20),
            height: getHeightPDF(user.paddingPhoto), //800, //(841.7 - 20),
            margin: user.paddingPhoto,
            fontFamily: FONTFAMILY,
        },
        pageBackgroundColorNoImg: {
            backgroundColor: "white",
            width: "100%",
            height: 841.7,
            fontFamily: FONTFAMILY,
        },
        leftRowImage: {
            textAlign: "center",
            objectFit: "cover",
            width:
                getWidthPDF(user.paddingPhoto) -
                getWidthPDF(user.paddingPhoto) / ImageWidthConst,
            height: getHeightPDF(user.paddingPhoto) / 1.8,
            marginLeft: getWidthPDF(user.paddingPhoto) / ImageWidthConst / 2,
            marginRight: getWidthPDF(user.paddingPhoto) / ImageWidthConst / 2,
            marginTop: 40,
            fontFamily: FONTFAMILY,
        },
        leftRowImageDiv: {
            width:
                getWidthPDF(user.paddingPhoto) -
                getWidthPDF(user.paddingPhoto) / 3,
            height: getHeightPDF(user.paddingPhoto) / 1.7,
            marginLeft: getWidthPDF(user.paddingPhoto) / 3 / 2,
            marginRight: getWidthPDF(user.paddingPhoto) / 3 / 2,
            marginTop: 40,
            overflow: "hidden",
            fontFamily: FONTFAMILY,
        },
        backImage: {
            objectFit: "cover",
            width: 400,
            height: 600,
            fontFamily: FONTFAMILY,
        },
        body: {
            margin: 15,
            marginBottom: 5,
            textAlign: "justify",
            lineHeight: 2,
            fontFamily: FONTFAMILY,
        },
        bottomText: {
            margin: 5,
            marginTop: -25,
            fontSize: 10,
            textAlign: "center",
            fontFamily: FONTFAMILY,
        },
        userNameView: {
            position: "absolute",
            background: "red",
            right: "5",
            left: getXNameSpace(user.paddingPhoto),
            bottom: getYNameSpace(user.paddingPhoto),
            fontFamily: FONTFAMILY,
        },
        userName: {
            fontSize: 20,
            textAlign: "center",
            fontFamily: FONTFAMILY,
        },
        pageBackgroundColorBorder: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: user.colorBorder,
            fontFamily: FONTFAMILY,
        },
    });
    let borderVerification = user.paddingPhoto && user.paddingPhoto > 0;
    //imageUser = 'https://images.unsplash.com/photo-1602483845418-72c7cd88632c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80';
    let innerPage = (
        <View
            style={
                borderVerification
                    ? styles.pageBackgroundColor
                    : styles.pageBackgroundColorNoImg
            }
        >
            <Image src={pattern_pdf} style={styles.pageBackgroundStatic} />
            <Image style={styles.leftRowImage} src={imageUser} />
            <View style={styles.userNameView}>
                <Text style={styles.userName}>{formatTextUtf8(user.name)}</Text>
            </View>

            <Image src={logo_pdf} style={styles.pageBottomStatic} />
        </View>
    );
    if (borderVerification) {
        return (
            <Document>
                <Page size="A4" style={styles.page} wrap="false">
                    {user.fotoBackgroundType === "Imagem" ? (
                        <Image
                            style={styles.pageBackground}
                            src={getBorderServerImgPath(user.borderPhoto)}
                        />
                    ) : (
                        <View style={styles.pageBackgroundColorBorder} />
                    )}
                    {innerPage}
                </Page>
            </Document>
        );
    }
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap="false">
                {innerPage}
            </Page>
        </Document>
    );
};
export default Fotografia;
