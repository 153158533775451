import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";
import {
    formatTextUtf8,
    getDeadServerImgPath,
    getBorderServerImgPath,
    convertHTMLtoPDF,
    FONTFAMILY,
    stripHtml,
} from "../../utils/utils";
import FontSource from "../../utils/fonts/Mic32RegularRegular.ttf";
import FontSourceBold from "../../utils/fonts/Mic32BoldRegular.ttf";
import { weekDays, months } from "../../utils/utils";
import { reduceHooks } from "react-table";
// Register font
Font.register({
    family: FONTFAMILY,
    fonts: [
        { src: FontSource }, // font-style: normal, font-weight: normal
        { src: FontSourceBold, fontWeight: "bold" },
    ],
});

const logo = `/assets/imgs/${process.env.REACT_APP_MISSA_7_DIA_IMG}`;
const logo_pdf = `/assets/imgs/${process.env.REACT_APP_MISSA_LOGO}`;

const setTitleTopDistance = (padding, text) => {
    console.log(padding, text);

    if (text.includes("size")) {
        const textSize = parseInt(text.split("size:")[1].split(';"'));
        console.log(25 - Math.abs(textSize));
        return Math.abs(30 - textSize);
    }
    return 25;
    if (!padding) {
        padding = 0;
    }
    if (padding < 20) return 25 - padding;
    console.log(text);
    return 5;
};
const getWidthPDF = (padding) => {
    if (!padding) {
        padding = 0;
    }
    console.log("getWidthPDF", padding, 595 - padding * 2);
    return 595 - padding * 2;
};
const getHeightPDF = (padding) => {
    if (!padding) {
        padding = 0;
    }

    return 841.7 - padding * 2;
};
const Missa7diaPDF = (user) => {
    user = user.hasOwnProperty("user") ? user.user : user; //<View style={styles.pageBackgroundWhite}/>
    let imageUser = getDeadServerImgPath(user.image, user.id);

    const styles = StyleSheet.create({
        pageBackground: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            objectFit: "cover",
            fontFamily: FONTFAMILY,
        },
        pageBackgroundColorBorder: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: user.colorBorder,
            fontFamily: FONTFAMILY,
        },
        pageBackgroundFit: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 30,
            height: "220px",
            objectFit: "contain",
            fontFamily: FONTFAMILY,
            zIndex: 1000,
            border: "none",
        },
        pageBackgroundStatic: {
            position: "absolute",
            width: getWidthPDF(user.paddingMass),
            height: 240,
            left: 0,
            top: 5,
            objectFit: "contain",
            fontFamily: FONTFAMILY,
            zIndex: 1000,
            border: "none",
        },
        pageBottomStatic: {
            width:
                getWidthPDF(user.paddingMass) -
                30 -
                40 /** é o padding da página  */,
            height: 140,
            left: 0,
            top: 5,
            marginLeft: 20,
            marginRight: 20,
            objectFit: "contain",
            fontFamily: FONTFAMILY,
        },
        page: {
            padding: 0,
            backgroundColor: user.colorBorder,
        },
        pageBackgroundColor: {
            position: "relative",
            backgroundColor: "white",
            width: getWidthPDF(user.paddingMass), //(550 - 20),
            height: getHeightPDF(user.paddingMass), //800, //(841.7 - 20),
            margin: user.paddingMass,
            fontFamily: FONTFAMILY,
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        pageBackgroundColorNoImg: {
            backgroundColor: "white",
            position: "relative",
            width: "100%",
            height: "100%",
            paddingLeft: "15px",
            paddingRight: "15px",
            fontFamily: FONTFAMILY,
        },
        leftRowImage: {
            textAlign: "left",
            objectFit: "cover",
            width: "200px",
            height: "220px",
            marginLeft: 100,
            marginTop: 40,
            marginBottom: 50,
            fontFamily: FONTFAMILY,
        },
        backImage: {
            objectFit: "cover",
            width: 400,
            height: 600,
            fontFamily: FONTFAMILY,
        },
        body: {
            margin: 20,
            marginBottom: 5,
            textAlign: "justify",
            lineHeight: 2,
            fontFamily: FONTFAMILY,
        },
        bottomText: {
            margin: 5,
            marginLeft: 20,
            marginRight: 20,
            marginTop: -25,
            fontSize: 10,
            textAlign: "center",
            fontFamily: FONTFAMILY,
        },
    });
    let borderVerification = user.paddingMass && user.paddingMass > 0;
    let innerPage = (
        <View
            style={
                borderVerification
                    ? styles.pageBackgroundColor
                    : styles.pageBackgroundColorNoImg
            }
        >
            <Image
                src={logo}
                style={
                    borderVerification
                        ? styles.pageBackgroundStatic
                        : styles.pageBackgroundFit
                }
            />
            <Text
                style={{
                    fontFamily: FONTFAMILY,
                    position: "absolute",
                    fontWeight: "bold",
                    top: setTitleTopDistance(
                        user.paddingMass,
                        user.prayerPDFTitle
                    ),
                    left: 375 - user.paddingMass,
                    right: 10,
                    letterSpacing: 1,
                    maxHeight: 80,
                    zIndex: 3000,
                    wordBreak: "keepAll",
                }}
            >
                {convertHTMLtoPDF(
                    formatTextUtf8(user.prayerPDFTitle),
                    FONTFAMILY
                )}
            </Text>

            <Image style={styles.leftRowImage} src={imageUser} />

            <Text style={styles.body}>
                A Família de{" "}
                <Text
                    style={{
                        fontFamily: FONTFAMILY,
                        fontWeight: "bold",
                        marginBottom: 40,
                    }}
                >
                    {formatTextUtf8(user.name)}
                </Text>
                , comunica a todas as pessoas das suas relações e amizades, que
                será celebrada a {stripHtml(user.prayerPDFTitle)},{" "}
                {user.massPlace}, {weekDays[new Date(user.massDate).getDay()]},
                dia {new Date(user.massDate).getDate()} de{" "}
                {months[new Date(user.massDate).getMonth()]} de{" "}
                {new Date(user.massDate).getFullYear()} pelas {user.massHour}{" "}
                horas.
            </Text>
            <Text
                style={{
                    textAlign: "right",
                    marginRight: 20,
                    marginBottom: 0,
                    fontFamily: FONTFAMILY,
                }}
            >
                A Família Reconhecida...
            </Text>
            <Text
                style={{
                    textAlign: "center",
                    marginTop: 50,
                    fontFamily: FONTFAMILY,
                }}
            >
                Desde já a Família enlutada agradece a comparência,
            </Text>
            <Text
                style={{
                    fontFamily: FONTFAMILY,
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                {process.env.REACT_APP_LOCATION},{" "}
                {new Date(user.registryDate).getDate()} de{" "}
                {months[new Date(user.registryDate).getMonth()]} de{" "}
                {new Date(user.registryDate).getFullYear()}
            </Text>
            <Image src={logo_pdf} style={styles.pageBottomStatic} />
            <Text style={styles.bottomText}>
                Telem. {process.env.REACT_APP_TELM} .
                {process.env.REACT_APP_TELM1} .{process.env.REACT_APP_TELM2} |
                Telef. {process.env.REACT_APP_TEL} .{process.env.REACT_APP_TEL1}{" "}
                | Fax. {process.env.REACT_APP_FAX}{" "}
                {process.env.REACT_APP_STREET} |{" "}
                {process.env.REACT_APP_POSTAL_CODE}{" "}
                {process.env.REACT_APP_LOCATION} | {process.env.REACT_APP_CITY}{" "}
            </Text>
            <Text
                style={{
                    color: "#C51F7D",
                    textAlign: "center",
                    bottom: 0,
                    paddingBottom: 15,
                    fontFamily: FONTFAMILY,
                }}
            >
                {process.env.REACT_APP_WEBSITE}
            </Text>
        </View>
    );
    if (borderVerification) {
        return (
            <Document>
                <Page size="A4" style={styles.page} wrap="false">
                    {user.fotoBackgroundType === "Imagem" ? (
                        <Image
                            style={styles.pageBackground}
                            src={getBorderServerImgPath(user.border)}
                        />
                    ) : (
                        <View style={styles.pageBackgroundColorBorder} />
                    )}
                    {innerPage}
                </Page>
            </Document>
        );
    }
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap="false">
                {innerPage}
            </Page>
        </Document>
    );
};
export default Missa7diaPDF;
