import React, { Fragment } from "react";
import { Link } from "react-router-dom";

let currentItem = "Home";
const NavBar = (props) => {
    // Click Toggle menu
    const toggletNavActive = (item) => {
        currentItem = item;
    };
    const selected = {
        padding: "15px",
        background: "#313335",
        borderRadius: "5px",
        transition: "0.3s",
    };
    return (
        <Fragment>
            <div className="page-sidebar" style={{ height: "50px" }}>
                <div
                    className="sidebar custom-scrollbar"
                    style={{ height: "100%", width: "100%", margin: "auto" }}
                >
                    <Link
                        style={{
                            color: "white",
                            marginLeft: "15px",
                            textAlign: "center",
                        }}
                        to={`${process.env.PUBLIC_URL}${"/dashboard"}`}
                        className={`col sidebar-header ${true ? "active" : ""}`}
                        onClick={() => toggletNavActive("Home")}
                    >
                        <span style={currentItem === "Home" ? selected : {}}>
                            {"Home"}
                        </span>
                    </Link>
                    <Link
                        style={{
                            color: "white",
                            marginLeft: "15px",
                            textAlign: "center",
                        }}
                        to={`${process.env.PUBLIC_URL}${"/oracoes"}`}
                        className={`col sidebar-header ${
                            false ? "active" : ""
                        }`}
                        onClick={() => toggletNavActive("Orações")}
                    >
                        <span style={currentItem === "Orações" ? selected : {}}>
                            {"Orações"}
                        </span>
                    </Link>
                    <Link
                        style={{
                            color: "white",
                            marginLeft: "15px",
                            minWidth: "100px",
                            textAlign: "center",
                        }}
                        to={`${process.env.PUBLIC_URL}${"/novo"}`}
                        className={`col sidebar-header ${
                            false ? "active" : ""
                        }`}
                        onClick={() => toggletNavActive("Novo")}
                    >
                        <span style={currentItem === "Novo" ? selected : {}}>
                            {"Novo Registo"}
                        </span>
                    </Link>
                </div>
            </div>
        </Fragment>
    );
};

export default NavBar;
