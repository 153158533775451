import React from "react";
import PDFFormHeader from "../pdf_common/pdf_form_header";
import PrayerSelection from "../pdf_common/pdf_prayer_selection";
import NumberPicker from "../pdf_inputs/numberPicker";

const CartaoForm = ({ user, onChange, downloadButton, prayersList }) => {
    const onFieldChange = (event) => {
        // for a regular input field, read field name and value from the event
        const fieldName = event.target ? event.target.name : "prayerCard";
        const fieldValue = event.target ? event.target.value : event;

        onChange(fieldName, fieldValue);
    };
    const onChangePrayer = (prayer) => {
        const event = {
            target: {
                name: "prayerCard",
                value: prayer,
            },
        };

        onFieldChange(event);
    };

    return (
        <div className="col-sm-12">
            {" "}
            <div className="row">
                {" "}
                <div className="col-sm-12">
                    {" "}
                    <div className="card">
                        {" "}
                        <PDFFormHeader downloadButton={downloadButton} />{" "}
                        <div className="card-body">
                            {" "}
                            <form className="theme-form mega-form">
                                {" "}
                                <NumberPicker
                                    step="0.5"
                                    min="1"
                                    max="50"
                                    label={"Tamanho de letra (Nome)"}
                                    className="form-control"
                                    name="textSizeCard"
                                    onChange={(e) => onFieldChange(e)}
                                    value={user.textSizeCard}
                                />{" "}
                                <div className="form-group">
                                    {" "}
                                    <label className="col-form-label">
                                        Data de Registo
                                    </label>{" "}
                                    <input
                                        className="form-control"
                                        name="registryDate"
                                        type="date"
                                        onChange={(e) => onFieldChange(e)}
                                        value={user.registryDate}
                                    />{" "}
                                </div>{" "}
                                <div className="form-group">
                                    {" "}
                                    <label className="col-form-label">
                                        Data de Nascimento
                                    </label>{" "}
                                    <input
                                        className="form-control"
                                        name="birthDate"
                                        onChange={(e) => onFieldChange(e)}
                                        type="date"
                                        value={user.birthDate}
                                    />{" "}
                                </div>{" "}
                                <div className="form-group">
                                    {" "}
                                    <label className="col-form-label">
                                        Data de Óbito
                                    </label>{" "}
                                    <input
                                        className="form-control"
                                        name="deathDate"
                                        onChange={(e) => onFieldChange(e)}
                                        type="date"
                                        value={user.deathDate}
                                    />{" "}
                                </div>{" "}
                                <PrayerSelection
                                    setContents={
                                        user.prayerCard ? user.prayerCard : ""
                                    }
                                    prayersList={prayersList}
                                    user={user}
                                    onChange={(e) => onFieldChange(e)}
                                    onChangePrayer={onChangePrayer}
                                    setOptions={{
                                        height: 200,
                                        buttonList: [
                                            [
                                                "fontColor",
                                                "bold",
                                                "fontSize",
                                                "removeFormat",
                                            ],
                                        ],
                                    }}
                                />{" "}
                                <hr className="mt-4 mb-4" />{" "}
                            </form>{" "}
                        </div>{" "}
                    </div>{" "}
                </div>{" "}
            </div>{" "}
        </div>
    );
};

export default CartaoForm;
