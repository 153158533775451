import React from 'react';
import CartaoFrontPDF from './cartao_pdf_front'
import CartaoBackPDF from './cartao_pdf_back'
import { PDFViewer } from '@react-pdf/renderer';

const CartaoPDF = (props) => {
    return (
    <div>
        Frente
        <PDFViewer width="100%" height="500px" style={{background:"#484C4F"}}>
            <CartaoFrontPDF user={props.user}/>
        </PDFViewer>
        Verso
        <PDFViewer width="100%" height="500px" style={{background:"#484C4F"}}>
            <CartaoBackPDF user={props.user} />
        </PDFViewer> 
    </div>)
}
export default CartaoPDF;