import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import axios from "axios";
const API = `${process.env.REACT_APP_API}`;

const logo = `../assets/imgs/${process.env.REACT_APP_LOGO}`;

const Input = ({ loginAuth, onClick }) => {
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            loginAuth();
        }
    };
    return (
        <button
            onClick={onClick}
            onKeyDown={handleKeyDown}
            className="btn btn-primary btn-block"
            type="button"
        >
            Login
        </button>
    );
};

const Signin = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    /*const [value, setValue] = useState(() =>
        localStorage.getItem("profileURL" || man)
    );*/

    const history = props.history;
    const updateUser = props.updateUser;
    /* useEffect(() => {
        if (value !== null) localStorage.setItem("profileURL", value);
        else localStorage.setItem("profileURL", man);
    }, [value]);*/
    const loginAuth = async () => {
        try {
            const emailVer = email !== null && email !== "" && email.length > 2;
            const passwordVer =
                password !== null && password !== "" && password.length > 2;
            if (emailVer && passwordVer) {
                axios
                    .post(API, {
                        function: "login",
                        email: email,
                        password: password,
                    })
                    .then((res) => {
                        res = res.data;
                        if (res.code === 1) {
                            localStorage.setItem("userMortos", res.result);
                            updateUser(JSON.stringify(res.result));
                            history.push(`${process.env.PUBLIC_URL}/`);
                        } else {
                            setTimeout(() => {
                                toast.error(
                                    "Oppss.. As credênciais não correspodem a nenhum utilizador!"
                                );
                            }, 200);
                        }
                    });
            } else {
                setTimeout(() => {
                    toast.error("Preencha todos os campos correctamente");
                }, 200);
            }
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                toast.error(
                    "Oppss.. As credênciais não correspodem a nenhum utilizador!"
                );
            }, 200);
        }
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <img
                                                        src={logo}
                                                        width="100%"
                                                        alt=""
                                                    />
                                                </div>
                                                <br />
                                                <div className="text-center">
                                                    <h4>LOGIN</h4>
                                                    {/*<h6>Enter your Username and Password </h6>*/}
                                                </div>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">
                                                            Email
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="email"
                                                            name="email"
                                                            value={email}
                                                            onChange={(e) =>
                                                                setEmail(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        {/*errors.email && 'Email é obrigatório'*/}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">
                                                            Password
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="password"
                                                            name="password"
                                                            value={password}
                                                            onChange={(e) =>
                                                                setPassword(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        {/*errors.password && 'Email é obrigatório'*/}
                                                    </div>

                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <Input
                                                            onClick={() =>
                                                                loginAuth()
                                                            }
                                                            loginAuth={
                                                                loginAuth
                                                            }
                                                        />
                                                    </div>
                                                    {/* <div className="login-divider"></div>
                                                    <div className="social mt-3">
                                                        <div className="form-group btn-showcase d-flex">
                                                            <button className="btn social-btn btn-fb d-inline-block" type="button" onClick={facebookAuth}> <i className="fa fa-facebook"></i></button>
                                                            <button className="btn social-btn btn-twitter d-inline-block" type="button" onClick={googleAuth}><i className="fa fa-google"></i></button>
                                                            <button className="btn social-btn btn-google d-inline-block" type="button" onClick={twitterAuth}><i className="fa fa-twitter"></i></button>
                                                            <button className="btn social-btn btn-github d-inline-block" type="button" onClick={githubAuth}><i className="fa fa-github"></i></button>
                                                        </div>
                                                    </div>*/}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default withRouter(Signin);
