import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";
import {
    formatTextUtf8,
    getDeadServerImgPath,
    convertHTMLtoPDF,
    FONTFAMILY,
    weekDays,
    months,
} from "../../utils/utils";

import FontSource from "../../utils/fonts/Mic32RegularRegular.ttf";
import FontSourceBold from "../../utils/fonts/Mic32BoldRegular.ttf";

// Register font
Font.register({
    family: FONTFAMILY,
    fonts: [
        { src: FontSource }, // font-style: normal, font-weight: normal
        { src: FontSourceBold, fontWeight: "bold" },
    ],
});

const ObituarioPDF = (user) => {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "white",
            padding: 40,
            paddingTop: 100,
            paddingBottom: 50,
        },
        title: {
            fontSize: 24,
            fontFamily: FONTFAMILY,
            textAlign: "center",
            fontFamily: FONTFAMILY,
        },
        author: {
            fontSize: 12,
            textAlign: "center",
            marginBottom: 40,
            fontFamily: FONTFAMILY,
        },
        singleLine: {
            textAlign: "left",
            fontSize: 14,
            marginBottom: 5,
            fontFamily: FONTFAMILY,
        },
        singleLineSmaller: {
            textAlign: "left",
            marginBottom: 10,
            fontSize: 12,
            fontFamily: FONTFAMILY,
        },
        singleLineBold: {
            textAlign: "left",
            marginBottom: 5,
            fontWeight: "bold",
            fontFamily: FONTFAMILY,
        },
        text: {
            fontSize: 14,
            textAlign: "justify",
            fontFamily: FONTFAMILY,
        },
        image: {
            marginVertical: 15,
            marginHorizontal: 100,
            fontFamily: FONTFAMILY,
        },
        section: {
            width: "100%",
        },
        imageSection: {
            position: "absolute",
            left: 130,
            top: 65,
            fontFamily: FONTFAMILY,
        },
        sectionImageSubs: {
            position: "absolute",
            left: 40,
            top: 310,
            fontFamily: FONTFAMILY,
        },
        leftRowImage: {
            textAlign: "left",
            objectFit: "cover",
            width: "160px",
            height: "180px",
            fontFamily: FONTFAMILY,
        },
        singleLineCenterFirst: {
            textAlign: "center",
            marginTop: 50,
            marginBottom: 20,
            fontSize: 12,
            fontFamily: FONTFAMILY,
        },
        singleLineCenterSecond: {
            textAlign: "center",
            marginTop: 15,
            marginBottom: 20,
            fontSize: 12,
            fontFamily: FONTFAMILY,
        },
        singleLineCenter: {
            textAlign: "center",
            fontSize: 12,
            marginBottom: 10,
            marginTop: 20,
            fontFamily: FONTFAMILY,
        },
        singleLineCenterBold: {
            textAlign: "center",
            fontSize: 14,
            marginBottom: 10,
            fontWeight: "bold",
            fontFamily: FONTFAMILY,
        },
        singleLineCenterIte: {
            textAlign: "center",
            fontSize: 14,
            fontFamily: FONTFAMILY,
        },
    });
    const getAge = (birthDate, deathDate) => {
        var diff_ms = new Date(deathDate) - new Date(birthDate).getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    };
    user = user.hasOwnProperty("user") ? user.user : user;
    let imageUser = getDeadServerImgPath(user.image, user.id);
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.imageSection}>
                    <Image style={styles.leftRowImage} src={imageUser} />
                </View>
                <View style={styles.sectionImageSubs}>
                    <Text style={styles.singleLineSmaller}>
                        {formatTextUtf8(user.header)}
                    </Text>
                    <Text style={styles.singleLineBold}>
                        {formatTextUtf8(user.name)}
                    </Text>
                    <Text style={styles.singleLine}>
                        {formatTextUtf8(user.residence)}, de{" "}
                        {user.birthDate && user.deathDate
                            ? getAge(user.birthDate, user.deathDate)
                            : "--"}{" "}
                        anos
                    </Text>
                    <Text style={styles.singleLine}>
                        {convertHTMLtoPDF(
                            formatTextUtf8(user.family),
                            FONTFAMILY
                        )}
                    </Text>
                    <Text style={styles.singleLineCenterFirst}>
                        {convertHTMLtoPDF(
                            formatTextUtf8(user.freeWriteObituary),
                            FONTFAMILY
                        )}
                    </Text>
                    <Text style={styles.singleLineCenterSecond}>
                        A todas as pessoas de sua relação e amizade, a família
                        comunica que o funeral se realiza,
                    </Text>
                    <Text style={styles.singleLineCenterBold}>
                        {weekDays[new Date(user.funeralDay).getDay()]}, dia{" "}
                        {new Date(user.funeralDay).getDate()} pelas{" "}
                        {user.startingHour} horas
                    </Text>
                    <Text style={styles.singleLineCenterIte}>
                        {convertHTMLtoPDF(
                            formatTextUtf8(user.departureArrivalCemeteryPlace),
                            FONTFAMILY
                        )}
                    </Text>
                    <Text style={styles.singleLineCenter}>
                        Desde já a Família enlutada agradece a comparência,
                    </Text>
                    <Text style={styles.singleLineCenterBold}>
                        {process.env.REACT_APP_LOCATION},{" "}
                        {new Date(user.registryDate).getDate()} de{" "}
                        {months[new Date(user.registryDate).getMonth()]} de{" "}
                        {new Date(user.registryDate).getFullYear()}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};
export default ObituarioPDF;
