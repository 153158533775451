import React, { Children } from "react";
import Loader from "./ItemLoader";

function StateManagement({
    status = "loading",
    onClickHandler = false,
    ...props
}) {
    const activeButton = {
        cursor: "pointer",
        border: "none",
        padding: "10px",
        marginBottom: "10px",
        outline: "none",
        color: "white",
        transition: "0.3s",
        backgroundColor: "rgb(68, 102, 242)",
    };
    const pageBody = () => {
        if (status === "loading") {
            return (
                <div className="container-fluid">
                    {" "}
                    <div className="row">
                        <div className="col-12">
                            <div className="card user-card">
                                <div
                                    className="card-body"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <p>A carregar ....</p>
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (status === "resolved") {
            return props.children;
        } else if (status === "rejected" && onClickHandler) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card user-card">
                                <div
                                    className="card-body"
                                    style={{
                                        color: "#d81a1a",
                                        textAlign: "center",
                                    }}
                                >
                                    <p>
                                        Ocorreu um erro, tente novalmente mais
                                        tarde.
                                    </p>
                                    <button
                                        className="badge badge-pill badge-light"
                                        style={activeButton}
                                        onClick={onClickHandler}
                                    >
                                        Tentar novamente
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card user-card">
                                <div
                                    className="card-body"
                                    style={{ color: "#d81a1a" }}
                                >
                                    Ocorreu um erro....
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    return pageBody();
}

export default StateManagement;
