import React, { useMemo } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";
import {
    formatTextUtf8,
    getDeadServerImgPath,
    convertHTMLtoPDF,
    FONTFAMILY,
} from "../../utils/utils";
import FontSource from "../../utils/fonts/Mic32RegularRegular.ttf";
import FontSourceBold from "../../utils/fonts/Mic32BoldRegular.ttf";
import { months } from "../../utils/utils";
// Register font
Font.register({
    family: FONTFAMILY,
    fonts: [
        { src: FontSource }, // font-style: normal, font-weight: normal
        { src: FontSourceBold, fontWeight: "bold" },
    ],
});
Font.registerHyphenationCallback((word) => [word]);
//const HTMLToPDF = {"p":};

const logoPrayer = `/assets/imgs/oracao.png`;
const decorLeft = `/assets/imgs/leftDecor.png`;

const deathIcon = `/assets/imgs/deathicon.png`;
const birthIcon = `/assets/imgs/birthicon.png`;
const logo_pdf = `/assets/imgs/${process.env.REACT_APP_PDF_LOGO}`;

const tel = `${process.env.REACT_APP_TELM}`;
const tel2 = `${process.env.REACT_APP_TELM2}`;

const PagelaPDF = (user) => {
    user = user.hasOwnProperty("user") ? user.user : user;
    const nameSize = user.pagelaNameSize ?? 14;
    console.log(nameSize, user);
    const styles = useMemo(
        () =>
            StyleSheet.create({
                page: {
                    backgroundColor: "white",

                    fontFamily: FONTFAMILY,
                },
                title: {
                    fontSize: 24,
                    textAlign: "center",
                    fontFamily: FONTFAMILY,
                },
                author: {
                    fontSize: 12,
                    textAlign: "center",
                    marginBottom: 40,
                    fontFamily: FONTFAMILY,
                },
                singleLine: {
                    textAlign: "left",
                    fontSize: 14,
                    marginBottom: 5,
                    fontFamily: FONTFAMILY,
                },
                singleLineSmaller: {
                    textAlign: "left",
                    marginBottom: 10,
                    fontSize: 12,
                    fontFamily: FONTFAMILY,
                },
                singleLineBold: {
                    textAlign: "left",
                    marginBottom: 5,
                    fontWeight: "bold",
                    fontFamily: FONTFAMILY,
                },
                singleLineBoldLeft: {
                    textAlign: "center",
                    marginBottom: 30,
                    fontWeight: "bold",
                    fontSize: nameSize,
                    whiteSpace: "wrap",
                    fontFamily: FONTFAMILY, //"-Bold",
                },
                dateTextLeft: {
                    textAlign: "center",
                    marginBottom: 15,
                    fontFamily: FONTFAMILY,
                },
                text: {
                    fontSize: 14,
                    textAlign: "justify",
                    fontFamily: FONTFAMILY,
                },
                image: {
                    marginVertical: 15,
                    marginHorizontal: 100,
                    fontFamily: FONTFAMILY,
                },
                section: {
                    width: "100%",
                },
                imageSection: {
                    position: "absolute",
                    left: 120,
                    top: 60,
                    width: "50%",
                    maxWidth: "50%",
                    fontFamily: FONTFAMILY,
                },
                sectionPrayer: {
                    position: "absolute",
                    right: 50,
                    left: 530,
                    top: 160,
                    fontFamily: FONTFAMILY,
                    textAlign: "justify",
                },
                leftRowImage: {
                    textAlign: "left",
                    objectFit: "cover",
                    width: "200px",
                    height: "220px",
                    marginBottom: 40,
                    fontFamily: FONTFAMILY,
                },
                singleLineCenterFirst: {
                    textAlign: "left",
                    marginTop: 50,
                    marginBottom: 20,
                    fontSize: 12,
                    fontFamily: FONTFAMILY,
                },
                singleLineCenter: {
                    textAlign: "center",
                    fontSize: 12,
                    marginBottom: 10,
                    fontFamily: FONTFAMILY,
                },
                singleLineCenterBold: {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 14,
                    marginBottom: 5,
                    fontFamily: FONTFAMILY, //bold
                },
                singleLineCenterBoldIte: {
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 14,
                    marginBottom: 30,
                    fontFamily: FONTFAMILY, //bold
                },
                pageBackgroundStatic: {
                    position: "absolute",
                    height: 100,
                    right: 50,
                    top: 50,
                    objectFit: "fit",
                },
                pageBackgroundStaticLeft: {
                    position: "absolute",
                    height: 100,
                    left: 72,
                    top: 100,
                    objectFit: "fit",
                },
                smallIconDeath: {
                    height: 20,
                    width: 10,
                    textAlign: "center",
                    objectFit: "fit",
                    position: "absolute",
                    bottom: 16,
                    left: 35,
                },
                smallIconBirth: {
                    height: 25,
                    width: 10,
                    textAlign: "center",
                    objectFit: "fit",
                    position: "absolute",
                    bottom: 50,
                    left: 35,
                },
                pageBottomStatic: {
                    width: 200,
                    height: 100,
                    right: 100,
                    bottom: 40,
                    objectFit: "contain",
                    position: "absolute",
                },
                bottomNumber: {
                    position: "absolute",
                    fontWeight: "bold",
                    bottom: 30,
                    right: 90,
                },
                dateTextLeftBottom: {
                    position: "absolute",
                    bottom: 75,
                    fontSize: 18,
                    left: 90,
                },
                dateTextLeftBottomBold: {
                    position: "absolute",
                    bottom: 55,
                    left: 80,
                    fontSize: 18,
                    fontWeight: "bold",
                    fontFamily: FONTFAMILY,
                },
                dateTextLeftBottomSmall: {
                    position: "absolute",
                    bottom: 30,
                    left: 95,
                    fontSize: 14,
                },
            }),
        [nameSize]
    );

    let imageUser = getDeadServerImgPath(user.image, user.id);
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.imageSection}>
                    <Image style={styles.leftRowImage} src={imageUser} />
                    <Text style={styles.singleLineBoldLeft}>
                        {formatTextUtf8(user.name)}
                    </Text>

                    <Image style={styles.smallIconBirth} src={birthIcon} />
                    <Text style={styles.dateTextLeft}>{user.birthDate}</Text>

                    <Image style={styles.smallIconDeath} src={deathIcon} />
                    <Text style={styles.dateTextLeft}>{user.deathDate}</Text>
                </View>
                <Image
                    style={styles.pageBackgroundStaticLeft}
                    src={decorLeft}
                />
                <Image style={styles.pageBackgroundStatic} src={logoPrayer} />
                <View style={styles.sectionPrayer}>
                    {convertHTMLtoPDF(user.prayerPagela, FONTFAMILY)}
                </View>
                <Text style={styles.dateTextLeftBottom}>
                    A Família Agradece, Reconhecida.
                </Text>
                <Text style={styles.dateTextLeftBottomBold}>
                    Dá-lhe, Senhor, o Eterno Descanso!
                </Text>
                <Text style={styles.dateTextLeftBottomSmall}>
                    {process.env.REACT_APP_LOCATION},{" "}
                    {new Date(user.registryDate).getDate()} de{" "}
                    {months[new Date(user.registryDate).getMonth()]} de{" "}
                    {new Date(user.registryDate).getFullYear()}
                </Text>
                <Image src={logo_pdf} style={styles.pageBottomStatic} />
                <Text style={styles.bottomNumber}>
                    {tel} . {tel2}
                </Text>
            </Page>
        </Document>
    );
};
export default PagelaPDF;
