import React from "react";

const Footer = props => {
    return (
    <footer className="footer" style={{"marginLeft":"0px"}}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 footer-copyright" style={{textAlign:"center"}}>
                    <p className="mb-0">Copyright 2020 © CARDOSO&MARTINS</p>
                </div>
            </div>
        </div>
</footer>
)};

export default Footer;