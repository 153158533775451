import React, { Fragment, Component } from "react";
import PDFFull from "../../pages/pdf";
import {
    fetchAxios,
    getDeadServerImgPath,
    formatTextUtf8,
} from "../../utils/utils";
import qs from "qs";
import { toast } from "react-toastify";
import StateManegement from "../common/StateManegement";

const RATIO = parseFloat(process.env.REACT_APP_ASPECT_RATION_CROP);
let context;
class Funer_Home extends Component {
    constructor(props) {
        super(props);
        context = this;

        const params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });
        const id = params.id;
        this.state = {
            user: {},
            prayers: {},
            id,
            tab: "default",
            status: "loading",
        };
    }
    getPrayers() {
        const context = this;
        fetchAxios("getPrayers", {}, function (res) {
            if (res.data && res.data.code && res.data.code === 1) {
                context.setState({ prayers: res.data.result });
            } else {
                setTimeout(() => {
                    toast.error(
                        "Ocorreu um erro,não foi possivel recolher as orações já existentes!"
                    );
                }, 200);
            }
        });
    }
    onChangeTab(type) {
        this.setState({ tab: type });
    }
    getDead() {
        const id = this.state.id;
        const context = this;
        fetchAxios("getDead", { id }, function (result) {
            if (result.data.code === 1) {
                if (
                    !result.data.result.cropParams ||
                    result.data.result.cropParams === ""
                ) {
                    result.data.result.cropParams = {
                        unit: "px",
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                        units: "px",
                        aspect: RATIO,
                    };
                }
                context.setState({
                    user: result.data.result,
                    status: "resolved",
                });
            } else {
                context.setState({
                    status: "rejected",
                });
            }
        });
    }
    updateUserParent(user) {
        let userNew = user;
        context.setState({ user: userNew });
    }
    componentDidMount() {
        this.fecthData();
    }
    fecthData() {
        this.getDead();
        this.getPrayers();
    }
    render() {
        let imageUser = getDeadServerImgPath(
            this.state.user.image,
            this.state.user.id
        );
        return (
            <StateManegement
                status={this.state.status}
                onClickHandler={() => this.fecthData()}
            >
                <Fragment>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card user-card">
                                    {this.state.user && this.state.user.name ? (
                                        <div className="card-body">
                                            {this.state.user.image && (
                                                <div className="user-card-image">
                                                    <img
                                                        style={{
                                                            maxWidth: "320px",
                                                            borderRadius:
                                                                "10px",
                                                        }}
                                                        className="img-fluid image-radius"
                                                        src={imageUser}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            <div className="user-deatils text-center">
                                                <h5>
                                                    {formatTextUtf8(
                                                        this.state.user.name
                                                    )}
                                                </h5>
                                            </div>
                                            <div className="row user-badge text-center">
                                                <a
                                                    className="mx-auto mt-2 col-11 col-md-2 badge badge-pill badge-light"
                                                    style={
                                                        this.state.tab ===
                                                        "obituario"
                                                            ? {
                                                                  backgroundColor:
                                                                      "#4466f2",
                                                                  color: "#fff",
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                            : {
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                    }
                                                    onClick={() =>
                                                        this.onChangeTab(
                                                            "obituario"
                                                        )
                                                    }
                                                >
                                                    {" "}
                                                    Obituário{" "}
                                                </a>
                                                <div className="clearfix"></div>
                                                <a
                                                    className="mx-auto mt-2 col-11 col-md-2 badge badge-pill badge-light"
                                                    style={
                                                        this.state.tab ===
                                                        "missa7dia"
                                                            ? {
                                                                  backgroundColor:
                                                                      "#4466f2",
                                                                  color: "#fff",
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                            : {
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                    }
                                                    onClick={() =>
                                                        this.onChangeTab(
                                                            "missa7dia"
                                                        )
                                                    }
                                                >
                                                    {" "}
                                                    Missa
                                                </a>
                                                <a
                                                    className="mx-auto mt-2 col-11 col-md-2 badge badge-pill badge-light"
                                                    style={
                                                        this.state.tab ===
                                                        "pagela"
                                                            ? {
                                                                  backgroundColor:
                                                                      "#4466f2",
                                                                  color: "#fff",
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                            : {
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                    }
                                                    onClick={() =>
                                                        this.onChangeTab(
                                                            "pagela"
                                                        )
                                                    }
                                                >
                                                    {" "}
                                                    Pagela{" "}
                                                </a>
                                                <a
                                                    className="mx-auto mt-2 col-11 col-md-2 badge badge-pill badge-light"
                                                    style={
                                                        this.state.tab ===
                                                        "cartao"
                                                            ? {
                                                                  backgroundColor:
                                                                      "#4466f2",
                                                                  color: "#fff",
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                            : {
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                    }
                                                    onClick={() =>
                                                        this.onChangeTab(
                                                            "cartao"
                                                        )
                                                    }
                                                >
                                                    {" "}
                                                    Cartão{" "}
                                                </a>
                                                <a
                                                    className="mx-auto mt-2 col-11 col-md-2 badge badge-pill badge-light"
                                                    style={
                                                        this.state.tab ===
                                                        "fotografia"
                                                            ? {
                                                                  backgroundColor:
                                                                      "#4466f2",
                                                                  color: "#fff",
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                            : {
                                                                  cursor:
                                                                      "pointer",
                                                              }
                                                    }
                                                    onClick={() =>
                                                        this.onChangeTab(
                                                            "fotografia"
                                                        )
                                                    }
                                                >
                                                    {" "}
                                                    Fotografia{" "}
                                                </a>
                                            </div>
                                            {this.state.tab !== "default" ? (
                                                <PDFFull
                                                    key={this.state.tab}
                                                    prayers={this.state.prayers}
                                                    user={this.state.user}
                                                    type={this.state.tab}
                                                    updateUserParent={
                                                        this.updateUserParent
                                                    }
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Selecione uma tab para ver o
                                                    formulário/PDF
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <p
                                            style={{
                                                height: "200px",
                                                width: "100%",
                                                textAlign: "center",
                                                lineHeight: "200px",
                                            }}
                                        >
                                            O morto pretendido não foi
                                            encontrado....
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </StateManegement>
        );
    }
}

export default Funer_Home;
