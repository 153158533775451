import React, {
    Fragment,
    useReducer,
    useRef,
    useCallback,
    useEffect,
} from "react";
import StateManegement from "../common/StateManegement";
import Datatable from "../common/datatable";
import { formatTextUtf8, useSafeDispatch } from "../../utils/utils";
import axios from "axios";
const API = `${process.env.REACT_APP_API}`;

const stateReducer = (state, action) => {
    switch (action.type) {
        case "loading":
            return { ...state, status: "loading" };
        case "update":
            return {
                ...state,
                status: "resolved",
                filterUsers: action.filterUsers,
            };
        case "resolved":
            return {
                ...state,
                status: "resolved",
                filterUsers: action.filterUsers,
                users: action.users,
            };
        case "rejected":
            return { ...state, status: "rejected" };
        default:
            throw new Error(`${action.type} is not a valid type`);
    }
};
const initReducer = {
    users: [],
    filterUsers: [],
    status: "loading",
};

function HomePage({ filterText, ...props }) {
    const [state, unsafeDispatch] = useReducer(stateReducer, initReducer);
    const dispatch = useSafeDispatch(unsafeDispatch);
    const { users, filterUsers, status } = state;
    const filterTextInput = useRef();
    const filter = {
        outline: "none",
        width: "100%",
        textAlign: "center",
        height: "40px",
        borderRadius: "20px",
        border: "none",
        marginBottom: "20px",
    };
    const cleanUtf8 = (users) => {
        users.forEach((user) => {
            user["Nome"] = formatTextUtf8(user["Nome"]);
        });
        return users;
    };
    const fetchDeadsCall = () => {
        dispatch({ type: "loading" });
        axios.post(API, { function: "getDeadsTable" }).then((res) => {
            res = res.data;
            if (res.code === 1) {
                const users = cleanUtf8(res.result);
                dispatch({ type: "resolved", users, filterUsers: users });
            } else {
                dispatch({ type: "rejected" });
            }
        });
    };
    const fetchDeads = useCallback(fetchDeadsCall, [dispatch]);
    useEffect(fetchDeads, []);
    const handleChange = () => {
        let isEmpty = !(filterTextInput.current.value.trim().length > 0);
        const temp = [...users];

        dispatch({
            type: "update",
            filterUsers: isEmpty
                ? temp
                : temp.filter((o) =>
                      o.Nome.toLowerCase().includes(
                          filterTextInput.current.value.toLowerCase()
                      )
                  ),
        });
    };
    return (
        <StateManegement status={status}>
            <Fragment>
                <div className="container-fluid" key={filterText + "_"}>
                    <input
                        type="text"
                        placeholder="Filtrar..."
                        value={filterText}
                        ref={filterTextInput}
                        onChange={handleChange}
                        style={filter}
                    />
                    {filterUsers && filterUsers.length > 0 ? (
                        <Datatable
                            defaultSorted={[
                                {
                                    id: "ID",
                                    desc: true,
                                },
                            ]}
                            key={filterText + filterUsers.length}
                            multiSelectOption={false}
                            myData={filterUsers}
                            myClass={"backgroundwhiterowtable"}
                            pageSize={filterUsers.length}
                            pagination={
                                filterUsers && filterUsers.length > 100
                                    ? true
                                    : false
                            }
                            rowColor={"white"}
                            class="-striped -highlight"
                        />
                    ) : (
                        <p style={{ textAlign: "center" }}>Sem Resultados</p>
                    )}
                </div>
            </Fragment>
        </StateManegement>
    );
}

export default HomePage;
