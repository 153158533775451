import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Slider from "react-input-slider";
import { SketchPicker } from "react-color";
import PDFFormHeader from "../pdf_common/pdf_form_header";
import Switch from "react-switch";
import PDFFormGallery from "../pdf_common/pdf_form_gallery";
import { toast } from "react-toastify";
import axios from "axios";
import { isValidResponse, getDeadServerImgPath } from "../../utils/utils";

const API = `${process.env.REACT_APP_API}`;
let imageRef;
let fileUrl;
let imageBlob = "";
let typeBackground = "";
let timeoutPhoto = 0;
const RATIO = parseFloat(process.env.REACT_APP_ASPECT_RATION_CROP);

const FotografiaForm = ({ user, onChange, downloadButton }) => {
    const [statusGallery, setStatusGallery] = useState("loading");
    const [statusGalleryBorders, setStatusGalleryBorders] = useState("loading");

    const handleChangeSwitch = (checked) => {
        typeBackground = checked ? "Imagem" : "Cor";
        const event = {
            target: { name: "fotoBackgroundType", value: typeBackground },
        };
        onFieldChange(event);
    };

    const selectedImage = (image) => {
        onChange("borderPhoto", image);
    };
    const clearImageAfterUpload = () => {
        const uploadClose = document.querySelectorAll(".dzu-previewButton");
        if (uploadClose.length > 0)
            Array.from(uploadClose).map((elem) => {
                elem.click();
            });
    };
    const selectedImageUser = (image, clearImgCrop = true) => {
        onChange("image", image);
        if (clearImgCrop) onChange("imageCrop", "");
        const event3 = {
            target: {
                name: "cropParams",
                value: {
                    unit: "px",
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    units: "px",
                    aspect: RATIO,
                },
            },
        };
        onFieldChange(event3);
    };

    const onFieldChange = (event) => {
        // for a regular input field, read field name and value from the event
        const fieldName = event.target.name; //(event.target && event.target.name) ? event.target.name : "padding";
        const fieldValue = event.target.value; //(event.target && event.target.value) ? event.target.value : parseInt(event.x);
        onChange(fieldName, fieldValue);
    };

    const changePadding = (padding) => {
        let newPadding = parseInt(padding.x);
        if (isNaN(newPadding)) newPadding = 0;
        const event = { target: { name: "paddingPhoto", value: newPadding } };
        onFieldChange(event);
    };
    const saveAppliedZoom = (user) => {
        if (user.imageCrop && imageBlob) {
            setStatusGallery("loading");
            window.scrollTo(0, 0);
            let data = new FormData();

            data.append("name", "image");
            data.append("file", imageBlob);

            let config = {
                header: {
                    "Content-Type": "multipart/form-data",
                },
            };

            axios
                .post(API, data, config)
                .then((response) => {
                    if (isValidResponse(response)) {
                        console.log(response);
                        setTimeout(() => {
                            setTimeout(() => {
                                selectedImageUser(response.data.result, false);
                                clearImageAfterUpload();
                            }, 700);
                            setTimeout(() => {
                                setStatusGallery("loading");
                                toast.success(
                                    "Imagem actualizada com sucesso!"
                                );
                            }, 900);
                        }, 500);
                        /*CroppedImgFlag = true;
          setTimeout(() => { CroppedImgFlag = true;}, 500);*/
                    } else {
                        setTimeout(() => {
                            setStatusGallery("resolved");
                            toast.error(
                                "Não foi possivel actualizar com sucesso!"
                            );
                        }, 500);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    };
    const clearAppliedZoom = (user) => {
        if (user.imageCrop) {
            const event = { target: { name: "imageCrop", value: "" } };
            onFieldChange(event);
            const event3 = {
                target: {
                    name: "cropParams",
                    value: {
                        unit: "px",
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                        units: "px",
                        aspect: RATIO,
                    },
                },
            };
            onFieldChange(event3);
            imageBlob = "";
        }
    };
    // If you setState the crop in here you should return false.
    const onImageLoaded = (image) => {
        imageRef = image;
    };

    const onCropComplete = (crop) => {
        if (timeoutPhoto) clearTimeout(timeoutPhoto);
        timeoutPhoto = setTimeout(() => {
            makeClientCrop(crop);
        }, 3000);
    };

    const onCropChange = (crop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        const event = { target: { name: "cropParams", value: crop } };
        onFieldChange(event);
    };
    const handleChangeCompleteColor = (color) => {
        const event = { target: { name: "colorBorder", value: color.hex } };
        onFieldChange(event);
    };
    async function makeClientCrop(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                crop,
                "newFile.jpeg"
            );
            onFieldChange({
                target: { name: "imageCrop", value: croppedImageUrl },
            });
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                imageBlob = blob;

                resolve(fileUrl);
            }, "image/jpeg");
        });
    };
    typeBackground = user.fotoBackgroundType;
    user.cropParams = !(user.cropParams && user.cropParams !== "-")
        ? { units: "px", aspect: RATIO }
        : user.cropParams;
    const buttonStyle = {
        cursor: "pointer",
        fontSize: "14px",
        padding: "0.375rem 1.75rem",
        letterSpacing: "0.7px",
        color: "white",
        backgroundColor: "#4466f2",
        borderColor: "#4466f2",
        borderRadius: "2px",
        display: "inline-block",
        width: "calc(50% - 30px)",
        marginRight: "15px",
        marginLeft: "15px",
        textAlign: "center",
    };
    return (
        <div className="col-sm-12">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <PDFFormHeader downloadButton={downloadButton} />
                        <div className="card-body">
                            <form className="theme-form mega-form">
                                <div className="form-group">
                                    <hr className="mt-4 mb-4" />
                                    <label className="col-form-label">
                                        Galeria
                                    </label>

                                    <PDFFormGallery
                                        /*key={"userGallery"}*/
                                        statusGallery={statusGallery}
                                        setStatusGallery={setStatusGallery}
                                        key={"userPhoto"}
                                        id={"userPhoto"}
                                        url={API + "?targetDir=" + user.id}
                                        isUser={true}
                                        idUser={user.id}
                                        selectedPhoto={user.image}
                                        onSelectImage={selectedImageUser.bind(
                                            this
                                        )}
                                        user={true}
                                    />
                                </div>
                                <div
                                    className="form-group"
                                    style={{ textAlign: "center" }}
                                >
                                    <hr className="mt-4 mb-4" />
                                    <label
                                        className="col-form-label"
                                        style={{
                                            width: "100%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Zoom (Selecione na imagem a área
                                        pretendida)
                                    </label>

                                    <ReactCrop
                                        src={getDeadServerImgPath(
                                            user.image,
                                            user.id
                                        )}
                                        crop={user.cropParams}
                                        ruleOfThirds
                                        onImageLoaded={onImageLoaded}
                                        onComplete={onCropComplete}
                                        onChange={onCropChange}
                                    />
                                    <div style={{ textAlign: "center" }}>
                                        <div
                                            style={buttonStyle}
                                            onClick={() =>
                                                saveAppliedZoom(user)
                                            }
                                        >
                                            Guardar Resultado na galeria
                                        </div>
                                        <div
                                            style={buttonStyle}
                                            onClick={() =>
                                                clearAppliedZoom(user)
                                            }
                                        >
                                            Remover Zoom
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <hr className="mt-4 mb-4" />
                                    <label className="col-form-label">
                                        Tipo de borda
                                    </label>
                                    <br />
                                    Cor{" "}
                                    <Switch
                                        onChange={handleChangeSwitch}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        checked={
                                            typeBackground === "Imagem"
                                                ? true
                                                : false
                                        }
                                    />{" "}
                                    Imagem
                                </div>
                                {typeBackground === "Imagem" ? (
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Imagem de Borda
                                        </label>
                                        <hr className="mt-4 mb-4" />
                                        <PDFFormGallery
                                            key={"borderPhoto"}
                                            id={"borderPhoto"}
                                            url={API + "?targetDir=borders"}
                                            isUser={false}
                                            statusGallery={statusGalleryBorders}
                                            setStatusGallery={
                                                setStatusGalleryBorders
                                            }
                                            idUser={user.id}
                                            selectedPhoto={user.borderPhoto}
                                            onSelectImage={selectedImage.bind(
                                                this
                                            )}
                                            user={false}
                                        />
                                    </div>
                                ) : (
                                    <div className="form-group">
                                        <hr className="mt-4 mb-4" />
                                        <label className="col-form-label">
                                            Escolha a cor de borda
                                        </label>

                                        <SketchPicker
                                            width="50%"
                                            color={user.colorBorder}
                                            onChangeComplete={
                                                handleChangeCompleteColor
                                            }
                                        />
                                    </div>
                                )}

                                <div className="form-group">
                                    <label className="col-form-label">
                                        Tamanho Borda
                                    </label>
                                    <br />
                                    <Slider
                                        axis="x"
                                        x={user.paddingPhoto}
                                        onChange={changePadding.bind(this)}
                                        step={1}
                                        xmax={100}
                                        xmin={0}
                                    />
                                </div>

                                <hr className="mt-4 mb-4" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FotografiaForm;
