import React, { Fragment, Component } from "react";
import DropzoneSingle from "./pdf_common/pdf_form_dropzone";
import { fetchAxios, isEmpty } from "../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var BreakException = {};
const UPLOADFOLDER = `${process.env.REACT_APP_UPLOAD_FOLDER}`;
let GlobalContext;
class Novo extends Component {
    constructor(props) {
        super(props);
        const context = this;
        GlobalContext = context;
        const date = new Date();
        const dateTimeFormat = new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const [
            { value: month },
            ,
            { value: day },
            ,
            { value: year },
        ] = dateTimeFormat.formatToParts(date);
        let requiredFields = ["name", "registryDate", "birthDate", "deathDate"];
        let user = {
            prayerCard: "",
            prayerPagela: "",
            cropParams: "",
            fotoBackgroundType: "Cor",
            registryDate: `${year}-${month}-${day}`,
            massDate: "",
            massHour: "",
            massPlace: "",
            border: "",
            image: `${process.env.REACT_APP_PLACEHOLDER_IMG}`,
            header: "",
            crop: "",
            name: "",
            colorBorder: "#000000",
            imageBorderPadding: 20,
            birthPlace: "",
            birthDate: "",
            deathDate: "",
            residence: "",
            family: "",
            weekday: "",
            funeralDay: "",
            startingHour: "",
            arrivalHour: "",
            arrivalPlace: "",
            departurePlace: "",
            cemetery: "",
            itinerary1: "",
            itinerary2: "",
            itinerary3: "",
            endText: "",
            endingInfo: "",
            paddingMass: 20,
            paddingPhoto: 20,
            prayersList: { 0: "", 1: "", 2: "" },
        };

        this.state = {
            user,
            warning: false,
            requiredFields,
            uploading: false,
        };
    }
    onSaveMorto = (event) => {
        const context = this;
        let warning = false;
        let uploading = this.state.uploading;
        try {
            context.state.requiredFields.forEach((element) => {
                if (isEmpty(this.state.user[element])) {
                    throw BreakException;
                }
            });
        } catch (e) {
            warning = true;
            context.setState({ warning: true });
        }
        if (!warning && !uploading) {
            fetchAxios("addDead", this.state.user, function (res) {
                if (res.data && res.data.code === 1) {
                    setTimeout(() => {
                        toast.success("Adicionado com sucesso");
                        context.props.history.push(
                            "/user?id=" + res.data.result
                        );
                    }, 200);
                } else {
                    setTimeout(() => {
                        toast.error("Ocorreu um erro!");
                    }, 200);
                }
            });
        } else if (warning) {
            setTimeout(() => {
                toast.error("Precisa de preencher os campos obrigatórios!");
            }, 200);
        } else if (uploading) {
            setTimeout(() => {
                toast.error(
                    "A imagem está a carregar! Espere que termine ou cancele."
                );
            }, 200);
        }
    };
    onFieldChange = (event) => {
        // for a regular input field, read field name and value from the event
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        this.onChange(fieldName, fieldValue);
    };
    onChange(field, value) {
        // parent class change handler is always called with field name and value

        this.setState((prevState) => ({
            user: {
                // object that we want to update
                ...prevState.user, // keep all other key-value pairs
                [field]: value, // update the value of specific key
            },
        }));
    }
    manageUpload(status, call) {
        const user = GlobalContext.state.user;
        switch (true) {
            case status === "exception_upload":
                GlobalContext.setState({ uploading: false });
                setTimeout(() => {
                    toast.error(
                        "Não foi possivel carregar, tente de novo ou se o erro persistir entre em contacto com a equipa de desenvolvimento."
                    );
                }, 200);
                break;
            case status === "preparing" || status === "restarted":
                GlobalContext.setState({ uploading: true });
                break;
            case status === "removed":
                user.image = `${process.env.REACT_APP_PLACEHOLDER_IMG}`;
                GlobalContext.setState({ uploading: false, user });
                break;
            case status === "getting_upload_params" ||
                status === "uploading" ||
                status === "headers_received":
                break;
            case status === "done":
                call = JSON.parse(call.response);
                user.image = call.result;
                GlobalContext.setState({ uploading: false, user });
                setTimeout(() => {
                    toast.success("Upload feito com sucesso!");
                }, 200);
                break;
            default:
                setTimeout(() => {
                    toast.error(
                        "Não foi possivel carregar, tente de novo ou se o erro persistir entre em contacto com a equipa de desenvolvimento."
                    );
                }, 200);
                break;
        }
    }

    render() {
        const requiredEmpty = {
            border: "solid #ca2d2d 1px",
        };
        let image = !this.state.user.image.includes("placeholder")
            ? UPLOADFOLDER + this.state.user.image
            : this.state.user.image;
        return (
            <Fragment>
                <br />
                <h3 style={{ marginLeft: "15px" }}>Novo Registo</h3>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <button
                                    className="btn btn-primary mr-1"
                                    onClick={this.onSaveMorto.bind(this)}
                                >
                                    Guardar
                                </button>
                                <form className="theme-form mega-form">
                                    <div
                                        className="form-group"
                                        style={{ textAlign: "center" }}
                                    >
                                        <img
                                            alt=""
                                            src={image}
                                            width="15%"
                                            style={{
                                                marginBottom: "30px",
                                                minWidth: "200px",
                                            }}
                                        />
                                        <DropzoneSingle
                                            callback={this.manageUpload}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Data de Registo*
                                        </label>
                                        <input
                                            className="form-control"
                                            style={
                                                isEmpty(
                                                    this.state.user.registryDate
                                                ) && this.state.warning
                                                    ? requiredEmpty
                                                    : {}
                                            }
                                            name="registryDate"
                                            type="date"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.registryDate}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Nome falecido*
                                        </label>
                                        <input
                                            className="form-control"
                                            required
                                            name="name"
                                            type="text"
                                            style={
                                                isEmpty(this.state.user.name) &&
                                                this.state.warning
                                                    ? requiredEmpty
                                                    : {}
                                            }
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            placeholder=""
                                            value={this.state.user.name}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Data de Nascimento*
                                        </label>
                                        <input
                                            className="form-control"
                                            name="birthDate"
                                            style={
                                                isEmpty(
                                                    this.state.user.birthDate
                                                ) && this.state.warning
                                                    ? requiredEmpty
                                                    : {}
                                            }
                                            type="date"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.birthDate}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Data de Óbito*
                                        </label>
                                        <input
                                            className="form-control"
                                            name="deathDate"
                                            style={
                                                isEmpty(
                                                    this.state.user.deathDate
                                                ) && this.state.warning
                                                    ? requiredEmpty
                                                    : {}
                                            }
                                            type="date"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.deathDate}
                                        />
                                    </div>
                                    {/*<div className="form-group">
                                    <label className="col-form-label">Local de Nascimento</label>
                                    <input className="form-control" name="birthPlace"  type="text" onChange={this.onFieldChange.bind(this)} value={this.state.user.birthPlace}/>
                                </div>*/}
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Residência do falecido
                                        </label>
                                        <input
                                            className="form-control"
                                            name="residence"
                                            type="text"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.residence}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Cabeçalho
                                        </label>
                                        <input
                                            className="form-control"
                                            name="header"
                                            type="text"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.header}
                                            placeholder="(descrição do falecido que no pdf aparece antes do nome)"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Familiares
                                        </label>
                                        <input
                                            className="form-control"
                                            name="family"
                                            type="text"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.family}
                                            placeholder="(lista de pessoas ex:Ana,João,..."
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Data de Funeral
                                        </label>
                                        <input
                                            className="form-control"
                                            name="funeralDay"
                                            type="date"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.funeralDay}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Nome do cemitério
                                        </label>
                                        <input
                                            className="form-control"
                                            name="cemetery"
                                            type="text"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.cemetery}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Local de saída
                                        </label>
                                        <input
                                            className="form-control"
                                            name="departurePlace"
                                            type="text"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={
                                                this.state.user.departurePlace
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Local de chegada
                                        </label>
                                        <input
                                            className="form-control"
                                            name="arrivalPlace"
                                            type="text"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.arrivalPlace}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Hora de inicio
                                        </label>
                                        <input
                                            className="form-control"
                                            name="startingHour"
                                            type="time"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.startingHour}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Hora de chegada
                                        </label>
                                        <input
                                            className="form-control"
                                            name="arrivalHour"
                                            type="time"
                                            onChange={this.onFieldChange.bind(
                                                this
                                            )}
                                            value={this.state.user.arrivalHour}
                                        />
                                    </div>
                                    <hr className="mt-4 mb-4" />
                                </form>
                                <button
                                    className="btn btn-primary mr-1"
                                    style={{ width: "100%" }}
                                    onClick={this.onSaveMorto.bind(this)}
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Novo;
