import React, { useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Helmet from 'react-helmet';
//un-comment this auth by using firebase only
//import app from './data/base';
import {useState} from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";

// Import custom Components 

import Funer_Home from './components/dashboard/funer_home';
import Oracoes from './components/oracoes';
import Novo from './components/novo_morto';
import HomePage from './components/dashboard/home';



import Signin from './auth/signin';

//config data
import configDB from './data/customizer/config'
import axios from 'axios';
import Loader from './components/common/loader';

const API = `${process.env.REACT_APP_API}`;
//firebase Auth only then un-comment this current User code
function Root() {
    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState(false);
    let verified = false;
    const validateUser = ((user,callback) => {
        if(user && user.hasOwnProperty("email") && user.hasOwnProperty("password"))
        {
            axios.post(API, {"function":"validate","email":user.email,"password":user.password})
            .then(res => {
                res = res.data;
                if(res.code === 1)
                {
                    callback(res.result);
                }
                else
                {
                    callback(false);
                }
                
            })
           
        }
        else
        {
            callback(false);
        }
    });
    const updateUser = (user) => {
        localStorage.setItem("userMortos",user);
        setCurrentUser(user);
    };
    const sessionRoutine = (sessionMortos,currentUser) => {
        if(!currentUser && sessionMortos)
        {   
            sessionMortos = JSON.parse(sessionMortos);
            validateUser(sessionMortos,function(res){
                if(res)
                {
                    setCurrentUser(sessionMortos);
                }
                verified = true;
            })
        }
        else
        {
            verified = true;
        }
    };
    let sessionMortos = localStorage.getItem("userMortos");
    sessionRoutine(sessionMortos,currentUser);
   /* else
    {
        setVerified(true);
    }*/
    useEffect(() => {
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        // app.auth().onAuthStateChanged(setCurrentUser);
        
        document.body.classList.add(layout);

        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;

        return function cleanup() {
            abortController.abort();
        }

    }, [abortController]);

    return (
        <div className="App">
             <Helmet>
                <meta charSet="utf-8" />
                <title>C&M</title>
                <meta name="description" content="Backoffice Cardoso & Martins" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <ScrollContext>
                        <Switch>
                           
                            {/*<Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/loginWithBgImg`} component={LoginWithBgImg} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/loginWithVideo`} component={LoginWithVideo} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/signup`} component={Signup} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/signupWithImg`} component={SignupWithImg} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/signupWithVideo`} component={SignupWithVideo} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} component={UnlockUser} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
                           
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} component={ComingSoonImg} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonVideo`} component={ComingSoonVideo} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
    <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />*/}

                            {/* NOTE :- If u want login with firebase only then uncomment this currentUser condition*/}
                            {( currentUser !== null && currentUser!== false) ? 
                            <Fragment>
                                <App>
                                    {/* dashboard menu */}
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/`} />)
                                    }} />
                                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Default} /> */}
                                    
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/`} component={HomePage} />
                                    <Route path={`${process.env.PUBLIC_URL}/user/`} component={Funer_Home} />
                                    <Route path={`${process.env.PUBLIC_URL}/oracoes/`} component={Oracoes} />
                                    <Route path={`${process.env.PUBLIC_URL}/novo/`} component={Novo} />
                                    {/*<Route path={`${process.env.PUBLIC_URL}/dashboard/default`} component={Default} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/ecommerce`} component={Ecommerce} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/university`} component={University} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/crypto`} component={Crypto} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/server`} component={ServerComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard/project`} component={Project} />*/}

                                   
                                   
                                </App>
                            </Fragment>
                            : (verified) ? 
                                <Signin updateUser={updateUser}/>
                                /*<Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} render={props => <currentUser {...props} updateUser = {updateUser} />}/>*/
                            :
                                <Loader/>
                            }}
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();