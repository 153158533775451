import React from 'react'

function NumberPicker({label="Escolha um número",name="numberPicker",onChange,value=1,...props}){
    return (
    <div className="form-group">
        <label className="col-form-label">{label}</label>
        <input {...props} name={name} onChange={onChange} value={value} type="number"/>
    </div>);
}
export default NumberPicker

