import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import "react-toastify/dist/ReactToastify.css";
import { makeid } from "../../utils/utils";
const API = `${process.env.REACT_APP_API}`;

const DropzoneSingle = (props) => {
    // specify upload params and url for your files
    const getUploadParams = ({ meta }) => {
        return { url: props.url ? props.url : API };
    };
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file, xhr }, status) => {
        props.callback(status, xhr);
    };
    return (
        <div className="container-fluid">
            <form className="dropzone digits">
                <div className="dz-message needsclick">
                    <Dropzone
                        getUploadParams={getUploadParams}
                        accept="image/*"
                        name={makeid(10)}
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent="Upload de Imagem (clique aqui ou arraste as imagens para aqui)"
                        styles={{
                            dropzone: {
                                width: "calc(100% - 10px)",
                                height: 100,
                            },
                            dropzoneActive: { borderColor: "green" },
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default DropzoneSingle;
