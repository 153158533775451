import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";
import { convertHTMLtoPDF, FONTFAMILY } from "../../utils/utils";
import FontSource from "../../utils/fonts/Mic32RegularRegular.ttf";
import FontSourceBold from "../../utils/fonts/Mic32BoldRegular.ttf";

// Register font
Font.register({
    family: FONTFAMILY,
    fonts: [
        { src: FontSource }, // font-style: normal, font-weight: normal
        { src: FontSourceBold, fontWeight: "bold" },
    ],
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        padding: 0,
        margin: 0,
    },
    sectionPrayer: {
        position: "absolute",
        textAlign: "left",
        right: 35,
        left: 35,
        top: 40,
        fontFamily: FONTFAMILY,
    },
});

const CartaoBackPDF = (user) => {
    user = user.hasOwnProperty("user") ? user.user : user;
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.sectionPrayer}>
                    {convertHTMLtoPDF(user.prayerCard, FONTFAMILY)}
                </View>
            </Page>
        </Document>
    );
};
export default CartaoBackPDF;
