import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";
import {
    formatTextUtf8,
    getDeadServerImgPath,
    FONTFAMILY,
} from "../../utils/utils";
import FontSource from "../../utils/fonts/Mic32RegularRegular.ttf";
import FontSourceBold from "../../utils/fonts/Mic32BoldRegular.ttf";

// Register font
Font.register({
    family: FONTFAMILY,
    fonts: [
        { src: FontSource }, // font-style: normal, font-weight: normal
        { src: FontSourceBold, fontWeight: "bold" },
    ],
});
Font.registerHyphenationCallback((word) => [word]);
const tel = `${process.env.REACT_APP_TELM}`;
const tel2 = `${process.env.REACT_APP_TELM2}`;
const deathIcon = `/assets/imgs/deathicon.png`;
const birthIcon = `/assets/imgs/birthicon.png`;
const logo_pdf = `/assets/imgs/${process.env.REACT_APP_PDF_LOGO}`;
const logobackground = `/assets/imgs/cartao_frente.png`;
const fontThin = `/assets/fonts/YantramanavThin.ttf`;

const CartaoFrontPDF = (user) => {
    user = user.hasOwnProperty("user") ? user.user : user;
    Font.register({
        family: "Thin",
        fonts: [
            {
                src: fontThin,
            },
        ],
    });
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "white",
            padding: 0,
            margin: 0,
        },
        title: {
            fontSize: 24,
            textAlign: "center",
            fontFamily: FONTFAMILY,
        },
        author: {
            fontSize: 12,
            textAlign: "center",
            marginBottom: 40,
            fontFamily: FONTFAMILY,
        },
        singleLine: {
            textAlign: "left",
            fontSize: 14,
            marginBottom: 5,
            fontFamily: FONTFAMILY,
        },
        singleLineSmaller: {
            textAlign: "left",
            marginBottom: 10,
            fontSize: 12,
            fontFamily: FONTFAMILY,
        },
        singleLineBold: {
            textAlign: "left",
            marginBottom: 5,
            fontWeight: "bold",
            fontFamily: FONTFAMILY,
        },
        text: {
            fontSize: 14,
            textAlign: "justify",
            fontFamily: FONTFAMILY,
        },
        image: {
            marginVertical: 15,
            marginHorizontal: 100,
            fontFamily: FONTFAMILY,
        },
        section: {
            width: "100%",
        },
        imageSection: {
            position: "absolute",
            left: 40,
            top: 120,
            fontFamily: FONTFAMILY,
        },
        sectionImageSubs: {
            position: "absolute",
            left: 40,
            top: 310,
            fontFamily: FONTFAMILY,
        },
        leftRowImage: {
            position: "absolute",
            left: 45,
            top: 45,
            objectFit: "cover",
            width: "365px",
            height: "450px",
            fontFamily: FONTFAMILY,
        },
        singleLineCenterFirst: {
            textAlign: "center",
            marginTop: 50,
            marginBottom: 20,
            fontSize: 12,
            fontFamily: FONTFAMILY,
        },
        singleLineCenter: {
            textAlign: "center",
            fontSize: 12,
            marginBottom: 10,
            fontFamily: FONTFAMILY,
        },
        singleLineCenterBold: {
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 14,
            marginBottom: 5,
            fontFamily: FONTFAMILY,
        },
        singleLineCenterBoldIte: {
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 14,
            marginBottom: 30,
            fontFamily: FONTFAMILY,
        },
        smallIconDeath: {
            height: 35,
            width: 12,
            textAlign: "center",
            objectFit: "fit",
            position: "absolute",
            top: 200,
            left: 5,
        },
        smallIconBirth: {
            height: 35,
            width: 12,
            textAlign: "center",
            objectFit: "fit",
            position: "absolute",
            top: 150,
            left: 5,
        },
        textSection: {
            position: "absolute",
            right: 55,
            top: 100,
            width: 220,
            height: 490,
            fontFamily: FONTFAMILY,
        },
        pageBackground: {
            position: "absolute",
            left: 140,
            right: 75,
            height: 500,
            top: 50,
            bottom: 4,
            objectFit: "contain",
        },
        singleLineBoldLeft: {
            textAlign: "left",
            fontSize: user.textSizeCard,
            marginTop: 20,
            marginBottom: 90,
            fontWeight: "bold",
            fontFamily: FONTFAMILY,
        },
        dateTextTop: {
            position: "absolute",
            textAlign: "center",
            marginBottom: 15,
            fontSize: 30,
            fontFamily: FONTFAMILY,
            top: 150,
            left: 5,
        },
        dateTextBottom: {
            position: "absolute",
            textAlign: "center",
            marginBottom: 15,
            fontSize: 30,
            fontFamily: FONTFAMILY,
            top: 200,
            left: 5,
        },
        bottomNumber: {
            position: "absolute",
            bottom: 30,
            right: 50,
            fontSize: 30,
            fontWeight: "thin",
            fontFamily: "Thin",
        },
        pageBottomStatic: {
            width: 300,
            height: 190,
            right: 50,
            bottom: 10,
            objectFit: "contain",
            position: "absolute",
        },
    });

    let imageUser = getDeadServerImgPath(user.image, user.id);
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <Image style={styles.pageBackground} src={logobackground} />
                <Image style={styles.leftRowImage} src={imageUser} />
                <View style={styles.textSection}>
                    <Text style={styles.singleLineBoldLeft}>
                        {formatTextUtf8(user.name)}
                    </Text>
                    <Image style={styles.smallIconBirth} src={birthIcon} />
                    <Text style={styles.dateTextTop}>{user.birthDate}</Text>
                    <Image style={styles.smallIconDeath} src={deathIcon} />
                    <Text style={styles.dateTextBottom}>{user.deathDate}</Text>
                </View>
                <Image src={logo_pdf} style={styles.pageBottomStatic} />
                <Text style={styles.bottomNumber}>
                    {tel} . {tel2}
                </Text>
            </Page>
        </Document>
    );
};
export default CartaoFrontPDF; /** 
<Image style={styles.pageBackgroundStatic} src={logoPrayer}/>
<Image src={logo_pdf} style={styles.pageBottomStatic}/> */
