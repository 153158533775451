import { parse } from "himalaya";
import { Text, View } from "@react-pdf/renderer";
import React from "react";
import axios from "axios";
const API = `${process.env.REACT_APP_API}`;
export const DEADIMGSPATH = `${process.env.REACT_APP_UPLOAD_FOLDER_DEADS}`;
export const BORDERSIMGSPATH = `${process.env.REACT_APP_UPLOAD_FOLDER_BORDERS}`;
export const FONTFAMILY = `${process.env.REACT_APP_FONTFAMILY}`
    ? `${process.env.REACT_APP_FONTFAMILY}`
    : "Mic32";

export const weekDays = {
    0: "Domingo",
    1: "Segunda-Feira",
    2: "Terça-Feira",
    3: "Quarta-Feira",
    4: "Quinta-Feira",
    5: "Sexta-Feira",
    6: "Sábado",
};
export const months = {
    0: "Janeiro",
    1: "Fevereiro",
    2: "Março",
    3: "Abril",
    4: "Maio",
    5: "Junho",
    6: "Julho",
    7: "Agosto",
    8: "Setembro",
    9: "Outubro",
    10: "Novembro",
    11: "Dezembro",
};

export const getBorderServerImgPath = (img) => {
    return BORDERSIMGSPATH + "/" + img;
};
export const getAge = (birthDate, deathDate) => {
    var diff_ms = new Date(deathDate) - new Date(birthDate).getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
};
export const getDeadServerImgPath = (img, id) => {
    return DEADIMGSPATH + id + "/" + img;
};
export const isEmpty = (val) => {
    return !(val && val.length > 0);
};
export const convertHTMLtoPDF = (input, font = false) => {
    let result = [];

    if (input && input !== "") {
        const parsedResult = parse(input);

        parsedResult.forEach((element, index) => {
            result.push(
                looperHTML(element, parsedResult[index].attributes, font)
            );
        });
    }
    result = (
        <View>
            {result.map(function (item, i) {
                return <Text key={encodeURIComponent(item) + i}>{item}</Text>;
            })}
        </View>
    );
    return result;
};
export const uploadFile = (input) => {
    console.log("uplaod file");
};
export const fetchAxios = (functionServer, data, callback) => {
    const functionObj = { function: functionServer };
    axios.post(API, { ...functionObj, ...data }).then((res) => {
        callback(res);
    });
};
export const isValidResponse = (res) => {
    if (res.data && res.data.code && res.data.code === 1) return true;
    return false;
};
export const formatTextUtf8 = (text) => {
    let res = "";
    try {
        res = decode_utf8(text);
    } catch (e) {
        res = text;
    }
    return res;
};
export const encode_utf8 = (s) => {
    return unescape(encodeURIComponent(s));
};
export const useSafeDispatch = (unsafeDispatch) => {
    const mounted = React.useRef(false);
    React.useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    });
    return React.useCallback(
        (...args) => (mounted.current ? unsafeDispatch(...args) : void 0),
        [unsafeDispatch]
    );
};
export const decode_utf8 = (s) => {
    return decodeURIComponent(escape(s));
};
export const componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
};
export const rgbToHex = (color) => {
    color = color.split(",");
    return (
        "#" +
        componentToHex(color[0]) +
        componentToHex(color[1]) +
        componentToHex(color[2])
    );
};
export const isRGB = (str) => {
    return str.match(
        /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/
    );
};
export const convertCSS = (str) => {
    let result = {};
    str = str.trim();
    let rgbTest = isRGB(str);
    switch (true) {
        case str.includes("font-size"):
            str = str.replace("font-size: ", "");
            str = str.replace("font-size:", "");
            result = { fontSize: str };
            break;
        case str.includes("background-color"):
            str = str.replace("background-color: ", "");
            str = str.replace("background-color:", "");
            if (rgbTest) {
                result = { backgroundColor: rgbToHex(rgbTest[0]) };
            }
            break;
        case str.includes("background"):
            str = str.replace("background: ", "");
            str = str.replace("background:", "");
            if (rgbTest) {
                result = { backgroundColor: rgbToHex(rgbTest[0]) };
            }
            break;
        case str.includes("color"):
            str = str.replace("color: ", "");
            str = str.replace("color:", "");
            result = { color: str };
            break;
        case str.includes("font-family"):
            str = str.replace("font-family: ", "");
            str = str.replace("font-family:", "");
            result = { fontFamily: str };
            break;
        case str.includes("text-align"):
            str = str.replace("text-align: ", "");
            str = str.replace("text-align:", "");
            result = { textAlign: str };
            break;
        default:
            return false;
    }
    return result;
};
export const parseStyle = (element, font = false) => {
    let styles =
        element && element.length > 0 ? element[0].value.split(";") : [];
    let cssArr = {};
    styles.forEach((styleRaw) => {
        cssArr = {
            ...cssArr,
            ...convertCSS(styleRaw),
        };
        if (font) {
            cssArr = { ...cssArr, fontFamily: font };
        }
    });
    return cssArr;
};
export const addDead = (dead, callback) => {
    axios.post(API, { function: "addDead", data: dead }).then((res) => {
        callback(res);
    });
};
export const looperHTML = (element, attributes, font = false) => {
    let final = <View></View>;
    let arr = [];
    if (element.hasOwnProperty("content") && element.type === "text") {
        let cssArr = {};

        cssArr = parseStyle(element.attributes, font);
        cssArr = {
            ...cssArr,
            ...parseStyle(attributes, font),
        };

        let content = element.content;
        return <Text style={cssArr}>{content.replace(/&nbsp;/g, " ")}</Text>;
    }
    if (element && element.tagName && element.tagName === "br") {
        return <Text style={{ color: "white" }}>space</Text>;
    }

    element.children.forEach((elementInner, index) => {
        let stylesToApply = parseStyle(attributes, font);
        if (element && element.tagName && element.tagName === "strong") {
            stylesToApply = {
                ...stylesToApply,
                ...{ fontFamily: font ? font : FONTFAMILY, fontWeight: "bold" },
            };
        }
        arr.push(
            <Text key={index} style={stylesToApply}>
                {looperHTML(elementInner, elementInner.attributes, font)}
            </Text>
        );
    });
    final = (
        <View>
            {arr.map(function (item, i) {
                return item;
            })}
        </View>
    );
    return final;
};
export const makeid = (length) => {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};
export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}
