import React, { useState } from "react";
import Slider from "react-input-slider";
import PDFFormHeader from "../pdf_common/pdf_form_header";
import { SketchPicker } from "react-color";
import Switch from "react-switch";
import PDFFormGallery from "../pdf_common/pdf_form_gallery";
import { formatTextUtf8 } from "../../utils/utils";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
const API = `${process.env.REACT_APP_API}`;

const Missa7diaForm = ({ user, onChange, downloadButton }) => {
    const [statusGallery, setStatusGallery] = useState("loading");
    let typeBackground = "";
    let backgrounds = [
        /*"https://instagram.fopo2-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/103006223_103675901337521_60353670201073675_n.jpg?_nc_ht=instagram.fopo2-1.fna.fbcdn.net&_nc_cat=105&_nc_ohc=6DiCYJU9dl4AX-xHFwy&oh=a92334842262e4d25b458afe9ddd864b&oe=5F3EEC4A","https://instagram.fopo2-2.fna.fbcdn.net/v/t51.2885-15/e35/101646904_2673069092792914_6259786713752142742_n.jpg?_nc_ht=instagram.fopo2-2.fna.fbcdn.net&_nc_cat=110&_nc_ohc=mpSvwT-fO-gAX9vT0VF&oh=6d0a0f9765317b71e9ffc7fdecde93d9&oe=5F474D7C"*/
    ];

    const handleChangeSwitch = (checked) => {
        typeBackground = checked ? "Imagem" : "Cor";
        const event = {
            target: { name: "fotoBackgroundType", value: typeBackground },
        };
        onFieldChange(event);
    };
    const onFieldChange = (event) => {
        // for a regular input field, read field name and value from the event
        /* const fieldName = (event.target && event.target.name) ? event.target.name : "padding";
      const fieldValue = (event.target && event.target.value) ? event.target.value : parseInt(event.x);*/
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        onChange(fieldName, fieldValue);
    };
    const changePadding = (padding) => {
        const event = {
            target: { name: "paddingMass", value: parseInt(padding.x) },
        };
        onFieldChange(event);
    };
    const selectedImage = (image) => {
        // for a regular input field, read field name and value from the event
        onChange("border", image);
    };
    const handleChangeCompleteColor = (color) => {
        const event = { target: { name: "colorBorder", value: color.hex } };
        onFieldChange(event);
    };
    const onFreeTextChange = (name, event) => {
        // for a regular input field, read field name and value from the event
        const fieldName = name;
        const fieldValue = event;

        onChange(fieldName, fieldValue);
    };

    typeBackground = user.fotoBackgroundType;
    return (
        <div className="col-sm-12">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <PDFFormHeader downloadButton={downloadButton} />
                        <div className="card-body">
                            <form className="theme-form mega-form">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Título de PDF
                                    </label>
                                    <SunEditor
                                        lang="pt_pt"
                                        setContents={
                                            user.prayerPDFTitle
                                                ? user.prayerPDFTitle
                                                : ""
                                        }
                                        onChange={onFreeTextChange.bind(
                                            this,
                                            "prayerPDFTitle"
                                        )}
                                        setOptions={{
                                            height: 200,
                                            buttonList: [
                                                [
                                                    "fontColor",
                                                    "bold",
                                                    "fontSize",
                                                    "removeFormat",
                                                ],
                                            ],
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Data de Registo
                                    </label>
                                    <input
                                        className="form-control"
                                        name="registryDate"
                                        type="date"
                                        onChange={onFieldChange.bind(this)}
                                        value={user.registryDate}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Data de Missa
                                    </label>
                                    <input
                                        className="form-control"
                                        name="massDate"
                                        onChange={onFieldChange.bind(this)}
                                        type="date"
                                        value={user.massDate}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Hora da Missa
                                    </label>
                                    <input
                                        className="form-control"
                                        name="massHour"
                                        onChange={onFieldChange.bind(this)}
                                        type="time"
                                        value={user.massHour}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Local da Missa
                                    </label>
                                    <input
                                        className="form-control"
                                        name="massPlace"
                                        onChange={onFieldChange.bind(this)}
                                        type="text"
                                        value={formatTextUtf8(user.massPlace)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Tipo de borda
                                    </label>
                                    <hr className="mt-4 mb-4" />
                                    <br />
                                    Cor{" "}
                                    <Switch
                                        onChange={handleChangeSwitch}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        checked={
                                            typeBackground === "Imagem"
                                                ? true
                                                : false
                                        }
                                    />{" "}
                                    Image
                                </div>
                                {typeBackground === "Imagem" ? (
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Imagem de Borda
                                        </label>
                                        <hr className="mt-4 mb-4" />

                                        <PDFFormGallery
                                            selectedPhoto={user.border}
                                            isUser={false}
                                            onSelectImage={selectedImage.bind(
                                                this
                                            )}
                                            backgrounds={backgrounds}
                                            url={API + "?targetDir=borders"}
                                        />
                                        {/*<DropzoneSingle callback={manageUploadBorder} url={API+"?targetDir=borders"}/>*/}
                                        {/*<input className="form-control" name="border" onChange={onFieldChange.bind(this)} type="text" value={user.border}/>*/}
                                    </div>
                                ) : (
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Escolha a cor de borda
                                        </label>
                                        <hr className="mt-4 mb-4" />
                                        <SketchPicker
                                            width="50%"
                                            color={user.colorBorder}
                                            onChangeComplete={
                                                handleChangeCompleteColor
                                            }
                                        />
                                    </div>
                                )}
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Tamanho Borda
                                    </label>
                                    <br />
                                    <Slider
                                        axis="x"
                                        x={parseFloat(user.paddingMass)}
                                        onChange={changePadding.bind(this)}
                                        step={1}
                                        xmax={100}
                                        xmin={0}
                                    />
                                </div>

                                <hr className="mt-4 mb-4" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Missa7diaForm;
