import React, { Fragment, Component } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

import ObituarioForm from "../components/pdf_inputs/obituario_form";
import ObituarioPDF from "../components/pdf_components/obituario_pdf";

import Missa7diaForm from "../components/pdf_inputs/missa7dia_form";
import Missa7diaPDF from "../components/pdf_components/missa7dia_pdf";

import PagelaForm from "../components/pdf_inputs/pagela_form";
import PagelaPDF from "../components/pdf_components/pagela_pdf";

import CartaoForm from "../components/pdf_inputs/cartao_form";
import CartaoPDF from "../components/pdf_components/cartao_pdf";
import CartaoFrontPDF from "../components/pdf_components/cartao_pdf_front";
import CartaoBackPDF from "../components/pdf_components/cartao_pdf_back";

import OracoesForm from "../components/pdf_inputs/oracoes_form";
import OracoesPDF from "../components/pdf_components/oracoes_pdf";

import FotografiaForm from "../components/pdf_inputs/fotografia_form";
import FotografiaPDF from "../components/pdf_components/fotografia_pdf";

import { fetchAxios } from "../utils/utils";

const componentsPDF = {
    obituario: ObituarioPDF,
    missa7dia: Missa7diaPDF,
    pagela: PagelaPDF,
    cartao: CartaoPDF,
    cartaoFront: CartaoFrontPDF,
    cartaoBack: CartaoBackPDF,
    oracoes: OracoesPDF,
    fotografia: FotografiaPDF,
    pdf: ObituarioPDF, //TODO change component
};
const componentsForm = {
    obituario: ObituarioForm,
    missa7dia: Missa7diaForm,
    pagela: PagelaForm,
    cartao: CartaoForm,
    oracoes: OracoesForm,
    fotografia: FotografiaForm,
    pdf: ObituarioForm, //TODO change component
};

class PDFFull extends Component {
    constructor(props) {
        super(props);
        const context = this;
        this.timeout = 0;

        this.state = {
            user: context.props.user,
            userPreview: context.props.user,
            id: context.props.id,
            type: context.props.type,
            prayers: context.props.prayers,
        };
    }

    onChange(field, value) {
        const context = this;

        if (JSON.stringify(this.state.user[field]) !== JSON.stringify(value)) {
            this.setState((prevState) => ({
                user: {
                    // object that we want to update
                    ...prevState.user, // keep all other key-value pairs
                    [field]: value, // update the value of specific key
                },
            }));
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                context.updateServer();
                context.updatePreview();
                context.updateUserParent();
            }, 500);
        }
    }
    updateServer() {
        delete this.state.user.onChange;
        delete this.state.user.downloadButton;
        delete this.state.user.__proto__;
        fetchAxios("updateDead", this.state.user, function (result) {
            console.log("updated Dead");
        });
    }
    updateUserParent = () => {
        delete this.state.user.onChange;
        delete this.state.user.downloadButton;
        delete this.state.user.__proto__;
        this.props.updateUserParent(this.state.user);
    };
    updatePreview = () => {
        this.setState({ userPreview: this.state.user });
    };
    getComponentForPDF = (type, props) => {
        const ComponentPDF = componentsPDF[type];
        return <ComponentPDF key={this.state.user.id + "_pdf"} user={props} />;
    };
    getComponentForForm = (type, user, onChange, downloadButton) => {
        const ComponentForm = componentsForm[type];
        return (
            <ComponentForm
                key={this.state.user.id + "_form"}
                user={user}
                onChange={onChange}
                downloadButton={downloadButton}
                prayersList={this.state.prayers}
            />
        );
    };
    generateDownloadButton = (type) => {
        //<button className="btn btn-primary mr-1">
        return (
            <div
                style={{
                    textAlign: "center",
                    width: "100%",
                    paddingBottom: "20px",
                }}
            >
                {type !== "cartao" ? (
                    <PDFDownloadLink
                        className="btn btn-primary mr-1"
                        key={this.state.user.id + "_download"}
                        document={this.getComponentForPDF(
                            this.state.type,
                            this.state.userPreview
                        )}
                        fileName={
                            this.state.user.name
                                ? this.state.user.name + ".pdf"
                                : "temp.pdf"
                        }
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? (
                                "A gerar..."
                            ) : (
                                <span style={{ color: "white" }}>Download</span>
                            )
                        }
                    </PDFDownloadLink>
                ) : (
                    <div>
                        <PDFDownloadLink
                            className="btn btn-primary mr-1"
                            key={this.state.user.id + "_downloadFront"}
                            document={this.getComponentForPDF(
                                "cartaoFront",
                                this.state.userPreview
                            )}
                            fileName={
                                this.state.user.name
                                    ? this.state.user.name + ".pdf"
                                    : "temp.pdf"
                            }
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? (
                                    "A gerar..."
                                ) : (
                                    <span style={{ color: "white" }}>
                                        Download Frente
                                    </span>
                                )
                            }
                        </PDFDownloadLink>

                        <PDFDownloadLink
                            className="btn btn-primary mr-1"
                            key={this.state.user.id + "_downloadBack"}
                            document={this.getComponentForPDF(
                                "cartaoBack",
                                this.state.userPreview
                            )}
                            fileName={
                                this.state.user.name
                                    ? this.state.user.name + ".pdf"
                                    : "temp.pdf"
                            }
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? (
                                    "A gerar..."
                                ) : (
                                    <span style={{ color: "white" }}>
                                        {" "}
                                        Download Verso
                                    </span>
                                )
                            }
                        </PDFDownloadLink>
                    </div>
                )}
            </div>
        );
    };
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12 col-xl-6">
                        {this.getComponentForForm(
                            this.state.type,
                            this.state.user,
                            this.onChange.bind(this),
                            this.generateDownloadButton(this.state.type)
                        )}
                    </div>
                    <div
                        className="col-sm-12 col-xl-6"
                        style={{ paddingBottom: "20px", minHeight: "500px" }}
                    >
                        {this.state.type !== "cartao" ? (
                            <PDFViewer
                                key={this.state.user.id + "_previewPDF"}
                                width="100%"
                                height="100%"
                                style={{ background: "#484C4F" }}
                            >
                                {this.getComponentForPDF(
                                    this.state.type,
                                    this.state.userPreview
                                )}
                            </PDFViewer>
                        ) : (
                            this.getComponentForPDF(
                                this.state.type,
                                this.state.userPreview
                            )
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default PDFFull;
