import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { formatTextUtf8 } from "../../utils/utils";

const PrayerSelection = ({
    user,
    prayersList = [],
    lang = "pt_pt",
    setContents = "",
    onChange,
    onChangePrayer,
    setOptions = {
        height: 200,
        buttonList: [["fontColor", "bold", "fontSize", "removeFormat"]],
    },
}) => {
    const buttonPrayer = {
        cursor: "pointer",
        border: "none",
        padding: "10px",
        marginBottom: "10px",
        outline: "none",
        color: "white",
        transition: "0.3s",
        background: "grey",
        flex: "0 0 25%",
        marginTop: "10px",
    };

    return (
        <div>
            <div className="form-group">
                <label className="col-form-label">Selecção de Oração</label>
                <br />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {prayersList &&
                        prayersList.map((prayer, i) => {
                            return (
                                <button
                                    type="button"
                                    key={prayer.id}
                                    className="badge badge-pill badge-light"
                                    style={buttonPrayer}
                                    onClick={() =>
                                        onChangePrayer(
                                            formatTextUtf8(prayer.body)
                                        )
                                    }
                                >
                                    {prayer.title}
                                </button>
                            );
                        })}
                </div>
            </div>
            <div className="form-group">
                <label className="col-form-label">Oração</label>
                <SunEditor
                    lang={lang}
                    setContents={setContents}
                    onChange={onChange.bind(this)}
                    setOptions={setOptions}
                />
                <br />
            </div>
        </div>
    );
};

export default PrayerSelection;
