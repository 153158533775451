import React from "react";
function ItemLoader() {
    return (
        <div
            style={{
                height: "auto",
                padding: "50px",
                width: "100%",
            }}
        >
            <div className="whirly-loader"> </div>
        </div>
    );
}
export default ItemLoader;
