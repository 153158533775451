import React, { useCallback, useEffect, useState, useRef } from "react";
import "react-image-lightbox/style.css";
import {
    fetchAxios,
    isValidResponse,
    DEADIMGSPATH,
    BORDERSIMGSPATH,
} from "../../utils/utils";
import { toast } from "react-toastify";
import DropzoneSingle from "../pdf_common/pdf_form_dropzone";
import StateManegement from "../common/StateManegement";

const IMGDEADSPATH = DEADIMGSPATH; //`http://funeraria.developers.pt/${process.env.REACT_APP_UPLOAD_FOLDER_DEADS}`;
const IMGBORDERSPATH = BORDERSIMGSPATH; //`${process.env.REACT_APP_UPLOAD_FOLDER_BORDERS}`;

function PDFFormGallery({
    isUser,
    idUser,
    statusGallery,
    setStatusGallery,
    onSelectImage,
    selectedPhoto,
    url,
}) {
    const [backgrounds, setBackgrounds] = useState([]);
    const complementaryPath = useRef(
        isUser ? IMGDEADSPATH + idUser + "/" : IMGBORDERSPATH
    );
    console.log("complementaryPath", complementaryPath);
    const getRequest = useCallback(
        ({ id, type }) => {
            fetchAxios(type, { id }, function (res) {
                if (isValidResponse(res)) {
                    console.log(JSON.parse(res.data.result));
                    setBackgrounds(JSON.parse(res.data.result));
                }
                setStatusGallery("resolved");
            });
        },
        [setStatusGallery]
    );
    const getBorders = useCallback(() => getRequest({ type: "getBorders" }), [
        getRequest,
    ]); //getRequest({ type: "getBorders" });
    const getDead = useCallback(
        (idUserToUse) => getRequest({ type: "getDeadImgs", id: idUserToUse }),
        [getRequest]
    );
    const clearImageAfterUpload = () => {
        const uploadClose = document.querySelectorAll(".dzu-previewButton");
        if (uploadClose.length > 0)
            Array.from(uploadClose).map((elem) => {
                elem.click();
            });
    };
    const manageUploadBorder = (status, call) => {
        switch (true) {
            case status === "exception_upload":
                setTimeout(() => {
                    toast.error(
                        "Não foi possivel carregar, tente de novo ou se o erro persistir entre em contacto com a equipa de desenvolvimento."
                    );
                }, 200);
                break;
            case status === "preparing" ||
                status === "restarted" ||
                status === "removed":
                break;
            case status === "getting_upload_params" ||
                status === "uploading" ||
                status === "headers_received":
                break;
            case status === "done":
                call = JSON.parse(call.response);
                onSelectImage(call.result, isUser);
                getBorders();

                setTimeout(() => {
                    toast.success("Upload feito com sucesso!");
                    clearImageAfterUpload();
                }, 200);
                break;
            default:
                setTimeout(() => {
                    toast.error(
                        "Não foi possivel carregar, tente de novo ou se o erro persistir entre em contacto com a equipa de desenvolvimento."
                    );
                }, 200);
                break;
        }
    };
    const manageUpload = (status, call) => {
        switch (true) {
            case status === "exception_upload":
                setTimeout(() => {
                    toast.error(
                        "Não foi possivel carregar, tente de novo ou se o erro persistir entre em contacto com a equipa de desenvolvimento."
                    );
                }, 200);
                break;
            case status === "preparing" ||
                status === "restarted" ||
                status === "removed":
                console.log("removed");
                break;
            case status === "getting_upload_params" ||
                status === "uploading" ||
                status === "headers_received":
                break;
            case status === "done":
                call = JSON.parse(call.response);

                onSelectImage(call.result, isUser);
                setTimeout(() => {
                    toast.success("Upload feito com sucesso!");
                    clearImageAfterUpload();
                    getDead(idUser);
                }, 200);
                setTimeout(() => {
                    getDead(idUser);
                }, 500);
                break;
            default:
                setTimeout(() => {
                    toast.error(
                        "Não foi possivel carregar, tente de novo ou se o erro persistir entre em contacto com a equipa de desenvolvimento."
                    );
                }, 200);
                break;
        }
    };
    useEffect(() => {
        console.log("gallery update");
        setTimeout(() => {
            if (isUser) {
                clearImageAfterUpload();
                getDead(idUser);
            } else {
                getBorders();
            }
        }, 500);
    }, [isUser, idUser, getDead, getBorders, statusGallery]);

    const images =
        backgrounds &&
        backgrounds.map((background) => {
            return (
                <figure key={"photoGallery_" + background} className="col-3">
                    <img
                        key={"photoGallery_" + background + "img"}
                        src={complementaryPath.current + background}
                        alt="Gallery"
                        className="img-thumbnail"
                        onClick={() => onSelectImage(background, isUser)}
                        style={
                            background === selectedPhoto
                                ? { border: "solid 2px #4465f2" }
                                : {}
                        }
                    />
                </figure>
            );
        });
    return (
        <div key={"photoGallery_" + (isUser ? "USER" : "BORDER")}>
            <StateManegement status={statusGallery}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="my-gallery card-body row">
                                    {images}
                                    {(!backgrounds ||
                                        backgrounds.length <= 0) &&
                                        "Sem Imagens"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StateManegement>
            <DropzoneSingle
                callback={!isUser ? manageUploadBorder : manageUpload}
                url={url}
            />
        </div>
    );
}
export default PDFFormGallery;
