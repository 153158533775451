import React from "react";

const PDFFormHeader = ({ downloadButton, key = "downloadButton" }) => {
    return (
        <div className="card-header" key={key}>
            <h5
                style={{
                    display: "inline-block",
                    height: "55px",
                    lineHeight: "37px",
                }}
            >
                Dados PDF
            </h5>
            <div style={{ float: "right" }}>{downloadButton}</div>
        </div>
    );
};

export default PDFFormHeader;
