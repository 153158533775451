import React, { Fragment, useState, useEffect } from "react";
import { useSafeDispatch } from "../../utils/utils";
const Loader = () => {
    const [show, unSafeSetShow] = useState(true);
    const setShow = useSafeDispatch(unSafeSetShow);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 1000);
    }, [show, setShow]);
    return (
        <Fragment>
            <div className={`loader-wrapper ${show ? "" : "loderhide"}`}>
                <div className="loader bg-white">
                    <div className="whirly-loader"> </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Loader;
