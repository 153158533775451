import React from "react";
import PDFFormHeader from "../pdf_common/pdf_form_header";
import PDFFormFreeText from "../pdf_common/pdf_form_freeText";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { formatTextUtf8 } from "../../utils/utils";

const ObituarioForm = ({ user, onChange, downloadButton, prayersList }) => {
    const onFieldChange = (event) => {
        // for a regular input field, read field name and value from the event
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        onChange(fieldName, fieldValue);
    };
    const onFreeTextChange = (name, event) => {
        // for a regular input field, read field name and value from the event
        const fieldName = name;
        const fieldValue = event;

        onChange(fieldName, fieldValue);
    };
    return (
        <div className="col-sm-12">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <PDFFormHeader downloadButton={downloadButton} />
                        <div className="card-body">
                            <form className="theme-form mega-form">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Data de Registo
                                    </label>
                                    <input
                                        className="form-control"
                                        name="registryDate"
                                        type="date"
                                        onChange={onFieldChange.bind(this)}
                                        value={user.registryDate}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Nome falecido
                                    </label>
                                    <input
                                        className="form-control"
                                        onChange={onFieldChange.bind(this)}
                                        name="name"
                                        type="text"
                                        placeholder=""
                                        value={formatTextUtf8(user.name)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Data de Nascimento
                                    </label>
                                    <input
                                        className="form-control"
                                        name="birthDate"
                                        onChange={onFieldChange.bind(this)}
                                        type="date"
                                        value={user.birthDate}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Data de Óbito
                                    </label>
                                    <input
                                        className="form-control"
                                        name="deathDate"
                                        onChange={onFieldChange.bind(this)}
                                        type="date"
                                        value={user.deathDate}
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="col-form-label">
                                        Residência do falecido
                                    </label>
                                    <input
                                        className="form-control"
                                        name="residence"
                                        onChange={onFieldChange.bind(this)}
                                        type="text"
                                        value={formatTextUtf8(user.residence)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Cabeçalho
                                    </label>
                                    <input
                                        className="form-control"
                                        name="header"
                                        onChange={onFieldChange.bind(this)}
                                        type="text"
                                        value={formatTextUtf8(user.header)}
                                        placeholder="(descrição do falecido que no pdf aparece antes do nome)"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Familiares (lista de pessoas
                                        ex:Ana,João,...)
                                    </label>
                                    <PDFFormFreeText
                                        contents={
                                            user.family
                                                ? formatTextUtf8(user.family)
                                                : ""
                                        }
                                        onChangeHandler={onFreeTextChange.bind(
                                            this,
                                            "family"
                                        )}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Campo livre
                                    </label>
                                    <PDFFormFreeText
                                        contents={
                                            user.freeWriteObituary
                                                ? formatTextUtf8(
                                                      user.freeWriteObituary
                                                  )
                                                : ""
                                        }
                                        onChangeHandler={onFreeTextChange.bind(
                                            this,
                                            "freeWriteObituary"
                                        )}
                                    />
                                    <br />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Data de Funeral
                                    </label>
                                    <input
                                        className="form-control"
                                        name="funeralDay"
                                        onChange={onFieldChange.bind(this)}
                                        type="date"
                                        value={user.funeralDay}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Local de chegada / saída / cemitério /
                                        Hora de chegada
                                    </label>
                                    <PDFFormFreeText
                                        contents={formatTextUtf8(
                                            user.departureArrivalCemeteryPlace
                                        )}
                                        onChangeHandler={onFreeTextChange.bind(
                                            this,
                                            "departureArrivalCemeteryPlace"
                                        )}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Nome do cemitério
                                    </label>
                                    <input
                                        className="form-control"
                                        name="cemetery"
                                        onChange={onFieldChange.bind(this)}
                                        type="text"
                                        value={formatTextUtf8(user.cemetery)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Local de saída
                                    </label>
                                    <input
                                        className="form-control"
                                        name="departurePlace"
                                        onChange={onFieldChange.bind(this)}
                                        type="text"
                                        value={formatTextUtf8(
                                            user.departurePlace
                                        )}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Hora de inicio
                                    </label>
                                    <input
                                        className="form-control"
                                        name="startingHour"
                                        onChange={onFieldChange.bind(this)}
                                        type="time"
                                        value={user.startingHour}
                                    />
                                </div>
                                {/*<div className="form-group">
                            <label className="col-form-label">Hora de chegada</label>
                            <input className="form-control" name="arrivalHour" onChange={onFieldChange.bind(this)} type="time" value={user.arrivalHour}/>
                        </div> */}

                                <hr className="mt-4 mb-4" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObituarioForm;
