import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const PDFFormFreeText = ({ contents = "", onChangeHandler, ...props }) => {
    function stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
    function handlePaste(e, cleanData, maxCharCount) {
        console.log({ e, cleanData, maxCharCount });
        onChangeHandler(contents + " " + stripHtml(cleanData));
    }
    function handleDrop(event) {
        console.log("handleDrop", event); //Get the drop event
    }
    function HandlePaste(event) {
        console.log("HandlePaste", event);
        //Get
    }

    return (
        <SunEditor
            lang="pt_pt"
            setContents={contents}
            //onDrop={handleDrop}
            //onPaste={handlePaste}
            onChange={onChangeHandler}
            setOptions={{
                height: 200,
                buttonList: [
                    ["align", "fontColor", "bold", "fontSize", "removeFormat"],
                ],
            }}
            {...props}
        />
    );
};

export default PDFFormFreeText;
