import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import { getDeadServerImgPath, weekDays, months } from "../../utils/utils";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#E4E4E4",
        padding: 40,
        paddingTop: 100,
        paddingBottom: 50
    },
    title: {
        fontSize: 24,
        textAlign: "center",
        fontFamily: "Times-Roman"
    },
    author: {
        fontSize: 12,
        textAlign: "center",
        marginBottom: 40,
        fontFamily: "Times-Roman"
    },
    singleLine: {
        textAlign: "left",
        fontSize: 14,
        marginBottom: 5,
        fontFamily: "Times-Roman"
    },
    singleLineSmaller: {
        textAlign: "left",
        marginBottom: 10,
        fontSize: 12,
        fontFamily: "Times-Roman"
    },
    singleLineBold: {
        textAlign: "left",
        marginBottom: 5,
        fontWeight: "heavy",
        fontFamily: "Times-Bold"
    },
    text: {
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "Times-Roman"
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
        fontFamily: "Times-Roman"
    },
    section: {
        width: "100%"
    },
    imageSection: {
        position: "absolute",
        left: 40,
        top: 120,
        fontFamily: "Times-Roman"
    },
    sectionImageSubs: {
        position: "absolute",
        left: 40,
        top: 310,
        fontFamily: "Times-Roman"
    },
    leftRowImage: {
        textAlign: "left",
        objectFit: "cover",
        width: "160px",
        height: "180px",
        fontFamily: "Times-Roman"
    },
    singleLineCenterFirst: {
        textAlign: "center",
        marginTop: 50,
        marginBottom: 20,
        fontSize: 12,
        fontFamily: "Times-Roman"
    },
    singleLineCenter: {
        textAlign: "center",
        fontSize: 12,
        marginBottom: 10,
        fontFamily: "Times-Roman"
    },
    singleLineCenterBold: {
        textAlign: "center",
        fontWeight: "heavy",
        fontSize: 14,
        marginBottom: 5,
        fontFamily: "Times-Bold"
    },
    singleLineCenterBoldIte: {
        fontWeight: "heavy",
        textAlign: "center",
        fontSize: 14,
        marginBottom: 30,
        fontFamily: "Times-Bold"
    }
});
const getAge = (birthDate, deathDate) => {
    var diff_ms = new Date(deathDate) - new Date(birthDate).getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
};
const OracoesPDF = user => {
    user = user.hasOwnProperty("user") ? user.user : user;
    let imageUser = getDeadServerImgPath(user.image, user.id);
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.imageSection}>
                    <Image style={styles.leftRowImage} src={imageUser} />
                </View>
                <View style={styles.sectionImageSubs}>
                    <Text style={styles.singleLineSmaller}>{user.header}</Text>
                    <Text style={styles.singleLineBold}>{user.name}</Text>
                    <Text style={styles.singleLine}>
                        {" "}
                        de{" "}
                        {user.birthDate && user.deathDate
                            ? getAge(user.birthDate, user.deathDate)
                            : "--"}{" "}
                        anos
                    </Text>
                    <Text style={styles.singleLine}>{user.family}</Text>
                    <Text style={styles.singleLineCenterFirst}>
                        A todas as pessoas de sua relação e amizade,a família
                        comunica que o funeral se realiza,
                    </Text>
                    <Text style={styles.singleLineCenterBold}>
                        {weekDays[new Date(user.funeralDay).getDay()]}, dia{" "}
                        {new Date(user.funeralDay).getDate()} pelas{" "}
                        {user.startingHour} horas
                    </Text>
                    <Text style={styles.singleLineCenterBoldIte}>
                        {user.itinerary1} {user.departurePlace}{" "}
                        {user.itinerary2} {user.arrivalHour} {user.arrivalPlace}{" "}
                        {user.itinerary3} {user.cemetery}
                    </Text>
                    <Text style={styles.singleLineCenter}>{user.endText}</Text>
                    <Text style={styles.singleLineCenterBold}>
                        {user.endingInfo}
                        {new Date(user.funeralDay).getDate()} de{" "}
                        {months[new Date(user.funeralDay).getMonth()]} de{" "}
                        {new Date(user.funeralDay).getFullYear()}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};
export default OracoesPDF;
