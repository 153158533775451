import React from 'react';

let props = {};
const onFieldChange = (event) => {
    // for a regular input field, read field name and value from the event
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    props.onChange(fieldName, fieldValue);
}
const OracoesForm = ({user,onChange, downloadButton,prayersList}) => {
    
    return (
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Dados PDF</h5>
                  </div>
                  <div className="card-body">
                    <form className="theme-form mega-form">
                        <div className="form-group">
                            <label className="col-form-label">Data de Registo</label>
                            <input className="form-control" name="registryDate" type="date" onChange={onFieldChange.bind(this)} value={(user.registryDate)}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Nome falecido</label>
                            <input className="form-control" onChange={onFieldChange.bind(this)} name="name" type="text" placeholder="" value={user.name}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Data de Nascimento</label>
                            <input className="form-control" name="birthDate" onChange={onFieldChange.bind(this)} type="date" value={user.birthDate}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Data de Óbito</label>
                            <input className="form-control" name="deathDate" onChange={onFieldChange.bind(this)} type="date" value={user.deathDate}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Residência do falecido</label>
                            <input className="form-control" name="residence" onChange={onFieldChange.bind(this)} type="text" value={user.residence}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Cabeçalho</label>
                            <input className="form-control" name="header" onChange={onFieldChange.bind(this)} type="text" value={user.header} placeholder="(descrição do falecido que no pdf aparece antes do nome)"/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Familiares</label>
                            <input className="form-control" name="family" onChange={onFieldChange.bind(this)} type="text" value={user.family} placeholder="(lista de pessoas ex:Ana,João,..."/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Data de Funeral</label>
                            <input className="form-control" name="funeralDay" onChange={onFieldChange.bind(this)} type="date" value={user.funeralDay}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Nome do cemitério</label>
                            <input className="form-control" name="cemetery" onChange={onFieldChange.bind(this)} type="text" value={user.cemetery}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Local de saída</label>
                            <input className="form-control" name="departurePlace" onChange={onFieldChange.bind(this)} type="text" value={user.departurePlace}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Local de chegada</label>
                            <input className="form-control" name="arrivalPlace" onChange={onFieldChange.bind(this)} type="text" value={user.arrivalPlace}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Hora de inicio</label>
                            <input className="form-control" name="startingHour" onChange={onFieldChange.bind(this)} type="time" value={user.startingHour}/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Hora de chegada</label>
                            <input className="form-control" name="arrivalHour" onChange={onFieldChange.bind(this)} type="time" value={user.arrivalHour}/>
                        </div>
                      <hr className="mt-4 mb-4" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
    );
};

export default OracoesForm;