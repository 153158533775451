import React, {
    Fragment,
    useReducer,
    useEffect,
    createRef,
    useState,
} from "react";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import StateManagement from "./common/StateManegement";
import { fetchAxios, formatTextUtf8 } from "../utils/utils";
import { toast } from "react-toastify";

const initialState = { oracoesArr: [], currentPrayer: 0 };
function init() {
    return { oracoesArr: [], currentPrayer: 0 };
}
function reducer(state, action) {
    switch (action.type) {
        case "updatePrayer":
            return {
                currentPrayer: state.currentPrayer,
                oracoesArr: action.oracoesArr,
            };
        case "addPrayer" || "removePrayer":
            return {
                currentPrayer: action.currentPrayer,
                oracoesArr: action.oracoesArr,
            };
        case "removePrayer":
            return {
                currentPrayer: action.currentPrayer,
                oracoesArr: action.oracoesArr,
            };
        case "changePrayer":
            return {
                currentPrayer: action.currentPrayer,
                oracoesArr: state.oracoesArr,
            };
        case "updatePrayerBody":
            const temp = state.oracoesArr;
            if (temp && temp[state.currentPrayer])
                temp[state.currentPrayer].body = action.value;
            return {
                currentPrayer: state.currentPrayer,
                oracoesArr: temp,
            };
        default:
            throw new Error();
    }
}
function Oracoes() {
    const [status, setStatus] = useState(() => "loading");
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const prayerTitleRef = createRef();
    useEffect(() => getPrayers(), []);
    const { currentPrayer, oracoesArr } = state;
    const onFieldChange = (event) => {
        // for a regular input field, read field name and value from the event
        const fieldValue = event;
        dispatch({ type: "updatePrayerBody", value: fieldValue });
    };
    const onTitleChange = () => {
        // for a regular input field, read field name and value from the event
        const temp = [...oracoesArr];
        let tempTitle = prayerTitleRef.current.value;
        if (currentPrayer <= oracoesArr.length - 1) {
            temp[currentPrayer].title = tempTitle;
            dispatch({ type: "updatePrayer", oracoesArr: temp });
        }
    };
    const getPrayers = () => {
        fetchAxios("getPrayers", {}, function (res) {
            if (res.data && res.data.code && res.data.code === 1) {
                dispatch({ type: "updatePrayer", oracoesArr: res.data.result });
                setStatus("resolved");
            } else {
                setStatus("rejected");
                setTimeout(() => {
                    toast.error(
                        "Ocorreu um erro,não foi possivel recolher as orações já existentes!"
                    );
                }, 200);
            }
        });
    };
    const savePrayer = () => {
        const temp = [...oracoesArr];
        fetchAxios("setPrayer", temp[currentPrayer], function (res) {
            if (res.data && res.data.code && res.data.code === 1) {
                const text = temp[currentPrayer].id
                    ? "Actualizado"
                    : "Adicionado";

                temp[currentPrayer].id = res.data.result;
                setTimeout(() => {
                    toast.success(text + " com sucesso");
                }, 200);
            } else {
                setTimeout(() => {
                    toast.error("Ocorreu um erro!");
                }, 200);
            }
        });
    };
    const onChangePrayer = (index) => {
        dispatch({ type: "changePrayer", currentPrayer: index });
    };
    const addPrayer = () => {
        const temp = [...oracoesArr];
        temp.push({ body: "", title: "---" });
        dispatch({
            type: "addPrayer",
            currentPrayer: temp.length - 1,
            oracoesArr: temp,
        });
    };
    const removeFromServer = (id) => {
        fetchAxios("removePrayer", { id }, function (res) {
            if (res.data && res.data.code && res.data.code === 1) {
                setTimeout(() => {
                    toast.success("Removido com sucesso");
                }, 200);
            } else {
                setTimeout(() => {
                    toast.error("Ocorreu um erro!");
                }, 200);
            }
        });
    };
    const removePrayer = () => {
        let temp = [...oracoesArr];

        if (temp[currentPrayer]) {
            let id = temp[currentPrayer].id ? temp[currentPrayer].id : -1;
            let newInd = currentPrayer - 1;
            temp.splice(currentPrayer, 1);

            if (newInd < 0) newInd = 0;
            //setOracoesArr(temp);
            dispatch({
                type: "removePrayer",
                currentPrayer: newInd,
                oracoesArr: temp,
            });
            if (id && id !== -1) {
                removeFromServer(id);
            }
        }
    };
    const buttonMain = {
        cursor: "pointer",
        border: "none",
        padding: "10px",
        marginBottom: "10px",
        outline: "none",
        color: "white",
        transition: "0.3s",
    };
    const greyButton = { ...buttonMain, background: "grey" };
    const activeButton = {
        ...buttonMain,
        backgroundColor: "rgb(68, 102, 242)",
    };
    const pageBody = () => {
        return (
            <StateManagement
                status={status}
                onClickHandler={() => getPrayers()}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card user-card">
                                <div className="card-body">
                                    {oracoesArr &&
                                        oracoesArr.map((oracao, i) => {
                                            let id = oracao.id;
                                            if (!oracao.id) {
                                                id = "new_" + i;
                                            }
                                            return (
                                                <button
                                                    className="badge badge-pill badge-light"
                                                    key={id}
                                                    style={
                                                        currentPrayer === i
                                                            ? activeButton
                                                            : greyButton
                                                    }
                                                    onClick={() =>
                                                        onChangePrayer(i)
                                                    }
                                                >
                                                    {" "}
                                                    {formatTextUtf8(
                                                        oracao.title
                                                    )}{" "}
                                                </button>
                                            );
                                        })}
                                    <button
                                        className="badge badge-pill badge-light"
                                        style={
                                            currentPrayer === oracoesArr.length
                                                ? greyButton
                                                : {
                                                      ...greyButton,
                                                      cursor: "pointer",
                                                  }
                                        }
                                        onClick={() => addPrayer()}
                                    >
                                        {" "}
                                        Nova{" "}
                                    </button>
                                    {!oracoesArr || oracoesArr.length <= 0
                                        ? " Sem Orações Guardadas"
                                        : ""}
                                    <br />
                                    <br />
                                    <br />
                                    Título
                                    <input
                                        ref={prayerTitleRef}
                                        className="form-control"
                                        name="title"
                                        onChange={(event) =>
                                            onTitleChange(event)
                                        }
                                        type="text"
                                        value={
                                            oracoesArr[currentPrayer]
                                                ? formatTextUtf8(
                                                      oracoesArr[currentPrayer]
                                                          .title
                                                  )
                                                : ""
                                        }
                                    />
                                    <br />
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Oração
                                        </label>
                                        <SunEditor
                                            setContents={
                                                oracoesArr[currentPrayer]
                                                    ? oracoesArr[currentPrayer]
                                                          .body
                                                    : ""
                                            }
                                            onChange={onFieldChange}
                                            lang="pt_pt"
                                            setOptions={{
                                                height: 200,
                                                buttonList: [
                                                    [
                                                        "fontColor",
                                                        "bold",
                                                        "fontSize",
                                                    ],
                                                ],
                                            }}
                                        />
                                        <br />
                                    </div>
                                    <button
                                        onClick={() => savePrayer()}
                                        className="btn btn-primary mr-1"
                                    >
                                        Guardar
                                    </button>
                                    <button
                                        onClick={() => removePrayer()}
                                        className="btn btn-alert mr-1"
                                    >
                                        Remover
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StateManagement>
        );
    };

    return (
        <Fragment>
            <h3 style={{ marginLeft: "15px" }}>Orações</h3>
            {pageBody()}
        </Fragment>
    );
}

export default Oracoes;
